import { combineReducers } from "redux";
import performSearchActionReducer from "./SearchActionReducer";
import ModalReducer from "./ModalReducer";
import messageReducer from "./MessageReducer";
import ExportActionReducer from "./ExportActionReducer";

import {
  START_LOADING,
  STOP_LOADING,
  SELECT_CONTROLLER,
  SELECT_POINT_OF_SALES,
  SELECT_USERS
} from "../actions/actionTypes";

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case START_LOADING:
      state = true;
      return state;
    case STOP_LOADING:
      state = false;
      return state;
    default:
      return state;
  }
};

const selectCommandReducer = (state = {id: "", username: "",creationDate: "",executionDate: "",commandAction: "" }, action) =>{
  if(action.type === "SELECT_COMMAND"){
    state = action.command;
      return state;
  }
  return state;
};

const selectControllerReducer = (state = {
  id: "",
  name: "",
  clientName: "",
  pos: {id: 0}
}, action) => {
  switch (action.type) {
    case SELECT_CONTROLLER:
      state = action.Controller;
      return state;
    case "CLEAR_SELECTED_CONTROLLER":
      state = {
        id: "",
        name: "",
        clientName: "",
      }
      return state;
    default:
      return state;
  }
};
const selectPointOfSalesReducer = (state = {
  id: "",
  name: "",
  clientName: "", longitude: "", latitude: ""
}, action) => {
  switch (action.type) {
    case SELECT_POINT_OF_SALES:
      state = action.PointOfSales;
      return state;
    case "CLEAR_SELECTED_POINT_OF_SALES":
      state = {
        id: "",
        name: "",
        clientName: "",
      }
      return state;
    default:
      return state;
  }
};
const selectusersReducer = (state = {
  id: "", pic: "", email: "",
  username: "", firstname: "", lastname: "" , poss: [] , gwebSso: false
}, action) => {
  switch (action.type) {
    case SELECT_USERS:
      state = action.users;
      return state;
    case "CLEAR_SELECTED_USERS":
      state = {
        id: "",
        username: "", firstname: "", lastname: "" , poss: []
      }
      return state;
    default:
      return state;
  }
};



const AppLifeCycleReducer = (state = {}, action) => {
  switch (action.type) {
    case "RESET_STATE":
      state = {};
      return state;
    default:
      return state;
  }
};

const DatatableReducer = (
  state = { refreshDatatable: 0, data: {} },
  action
) => {
  switch (action.type) {
    case "REFRESH_DATATABLE":
      state.refreshDatatable += 1;
      return state;
    case "DATA_TABLE_CONTENT":
      state.data = action.data;
      return state;
    default:
      return state;
  }
};
//OPEN_VERIFY_IDENTIRY_MODAL

const openVerifyIdentityModalReducer = (state = { tokenVerified: false, verifyDialogOpen: false }, action) => {
  switch (action.type) {
    case "OPEN_VERIFY_IDENTIRY_MODAL":
      state.verifyDialogOpen = true;
      state = { ...state, ...action.payload }
      break
    case "CLOSE_VERIFY_IDENTIRY_MODAL":
      state.verifyDialogOpen = false;
      break
    case "VALIDATE_IDENTITY":
      state.tokenVerified = true
      break
    default:
      return state;

  }
  return state;
};


const selectExportSuscriberReducer = (state = { companyCode: "", destination: "", exportFrequency: "", status: "DISABLED", automaticPayBack: false }, action) => {
  if (action.type === "SELECT_EXPORT_SUBSCRIBER") {
    state = action.exportSubscriber;
  } else if (action.type === "CLEAR_SELECTED_EXPORT_SUBSCRIBER") {
    state = { companyCode: "", destination: "", status: "DISABLED", exportFrequency: "", exportTemplate: "", automaticPayBack: false }
  }
  return state;
}


const selectExportReportReducer = (state = { companyCode: "", exportDate: "", confirmationDate: "", status: "" }, action = {}) => {
  if (action.type === "SELECT_EXPORT_REPORT") {
    state = action.exportSubscriber;
  } else if (action.type === "CLEAR_SELECTED_EXPORT_REPORT") {
    state = { companyCode: "", exportDate: "", confirmationDate: "", status: "" }
  }
  return state;
}



const commandModalReducer = (state = {open:false, controllerId:0}, action) => {
  if(action.type==="OPEN_COMMAND_MODAL"){
    state= {...state,open : true, controllerId :  action.payload?.controllerId}
  }
  if(action.type==="CLOSE_COMMAND_MODAL"){
    state={...state,open : false};
  }
  return state;
}


const wipEnabledReducer = (state = {enabled: false}, action) => {
  if(action.type === "WIP_ENABLED"){
    document.querySelector(".Body").style.zIndex = "-1"
    state = {enabled : true}
  }
  if(action.type === "WIP_DISABLED"){
    document.querySelector(".Body").style.zIndex = "unset"
    state= {enabled : false} 
  }
  return state;
}


const reducer = combineReducers({
  AppLifeCycleReducer,
  DatatableReducer,
  loadingReducer,
  selectControllerReducer,
  selectPointOfSalesReducer,
  selectusersReducer,
  performSearchActionReducer,
  ModalReducer,
  messageReducer,
  ExportActionReducer,
  openVerifyIdentityModalReducer,
  selectExportSuscriberReducer,
  selectExportReportReducer,
  selectCommandReducer,
  commandModalReducer,
  wipEnabledReducer
});

export default reducer;
