// need to configure the store globaly
export function showMessage(dispatch,errorCode,
  messageContent,
  messageType = "error",
  timeout = 3000
) {
  if(dispatch === undefined){
    throw new Error("please provide the dispatch function")
  }
  dispatch({
    type: "DISPATCH_MESSAGE",
    payload: { hide: false,code:errorCode , content: messageContent, type: messageType },
  });

  // fade the message after timeout
  setTimeout(() => {
    dispatch({
      type: "FADE_MESSAGE",
    });
  }, timeout);
}
