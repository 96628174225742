const ExportActionReducer = (state = {response: {}}, action) => {
  switch (action.type) {
    case "POPULATE_EXPORT_RESPONSE":
        state.response = action.payload
      return state;
    default:
      return state;
  }
};

export default ExportActionReducer ;
