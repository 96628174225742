import React from "react";
import { Input, IconButton, InputLabel, FormControl } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import "./SearchComponent.css";

const SearchComponent = (props) => {


  const dispatch = useDispatch();
  const searchModel = useSelector((state) => state.performSearchActionReducer);
  

  const handleOnChange = (event) => {
    dispatch({
      type: "UPDATE_SEARCH_MODEL_BY_EVENT",
      payload: { key: event.target.name, value: event.target.value },
    });
  };

  const clearData = () => {
    dispatch({
      type: "CLEAN_SEARCH_MODEL",
    });
  };

  const hideSearchPanel = () => {
    document.getElementsByClassName("search-panel")[0].classList.toggle("hide");
  };

  const performSearchAction = (model) => {
    if (model === undefined) {
      model = searchModel;
    }
    props.searchAction(model);
    document.getElementsByClassName("search-panel")[0].classList.toggle("hide");
  };

  /**
   *  default content of component
   */
  let defaulContent;
  if (searchModel === null || searchModel === undefined) {
    defaulContent = <div></div>;
  } else {
    defaulContent = Object.keys(searchModel).map((attr, index) => {
      return (
        <div key={index} style={{ width: "80%", margin: "auto"
        }}>
          <FormControl>
            <InputLabel>
              {props.labelsModel === undefined ? attr : props.labelsModel[attr]}
            </InputLabel>
            <Input
              name={attr}
              placeholder={
                props.labelsModel === undefined ? attr : props.labelsModel[attr]
              }
              value={searchModel[attr]}
              onChange={handleOnChange}
            />
          </FormControl>
        </div>
      );
    });
  }

  return (
    <div>
      <div className="search-panel-header">
        <h3>Search criteria </h3>
        <div onClick={hideSearchPanel} className="close-icons">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="search-panel-content">
        <div style={{ marginTop: "15px", maxHeight: "calc(65vh - 120px)", overflowX: "auto"  ,
        ...(Object.keys(searchModel).length > 3 ?  {display: "grid" , gridTemplateColumns: "repeat(2, calc(50% - 10px))"} : {})}}>
          {props.children !== undefined ? props.children : defaulContent}
        </div>

        <div className="action-panel">
          <IconButton onClick={() => performSearchAction()}>
            <i className="fa fa-search" />
          </IconButton>
          <IconButton onClick={clearData}>
            <i className="fa fa-close" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
