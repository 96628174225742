import axios from "axios";
import Configuarion from "../environment";



const deleteController = async (ControllerId) => {
    
    await axios.delete(Configuarion.backend_api_url + "/controllers", 
    {
        headers: {
            Authorization : localStorage.getItem("token")
        } , 
        params: {
          ids: ControllerId
        }
    });
    
}


export default deleteController ;