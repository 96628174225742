const Configuration = (function (env) {
  let conf = {
    backend_api_url: window.location.origin + "/api/backend",
  };
  switch (env) {
    case "LOCAL":
      return {
        ...conf,
        backend_api_url: "http://localhost:8888",
      };
    default:
      return {
        ...conf,
        oidc_authority: window._REACT_APP_OIDC_AUTHORITY_,
        oidc_redirect_uri: window._REACT_APP_OIDC_REDIRECT_URI_,
        oidc_client_id: window._REACT_APP_OIDC_CLIENT_ID_,
        oidc_metadata_url:window._REACT_APP_OIDC_AUTHORITY_ + "/.well-known/openid-configuration" ,
      };
  }
})(process.env.REACT_APP_ENV);
export default Configuration;
