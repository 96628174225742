import axios from "axios";
import Configuarion from "../environment";
import { showMessage } from "./MessageService";



const deletePointOfSales = async (ids) => {
    
    await axios.delete(Configuarion.backend_api_url + "/point-of-sales", 
    {
        headers: {
            Authorization : localStorage.getItem("token")
        } , 
        params: {
          ids
        }
    });
    
}


export default deletePointOfSales ;