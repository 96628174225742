import axios from "axios";
import Configuration from "../environment";





const fetchUserInfo = async (username) => {

    return await axios.get(Configuration.travel_api_url + `/users/userinfo?username=${username}`, {
        headers: {
            Authorization: localStorage.getItem("token")
        }
    });
}



const displayMenu = (ref) => {
    let tokenInfo = localStorage.getItem("token")?.replace("Bearer", "")?.trim()?.split(".")[1];
    if(tokenInfo === undefined){
        return "block";
    }
    let userData = JSON.parse(Buffer.from(tokenInfo,"base64"))

    console.log(ref?.current?.dataset?.sectag)
    switch(ref?.current?.dataset?.sectag){
        case "management-menu":
            return  userData.scopes.includes("ROLE_SUPER_ADMIN") || userData.scopes.includes("ROLE_ADMIN") || userData.scopes.includes("ROLE_MANAGER") ?  "block": "none" 
        case "ads-menu":
            return  userData.scopes.includes("ROLE_SUPER_ADMIN") || userData.scopes.includes("ROLE_ADMIN") || userData.scopes.includes("ROLE_ADS_ADMIN") ?  "block": "none"        
        default:
           return "block";
    }
    
  
}


export { fetchUserInfo, displayMenu };

