import {
  Badge, Button, CircularProgress, ClickAwayListener, Grow,
  IconButton, makeStyles, Menu, MenuItem, MenuList, Paper, Popper
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ContentMenu from "./component/ContentMenu";
import HorizenatlMenu from "./component/HorizentalMenu";
import Login from "./component/Login";
import Navigator from "./component/navigator";
import { clearSession } from "./services/AuthenticationService";

import axios from "axios";
import { Route, Switch, withRouter } from "react-router-dom";
import "./App.css";
import Configuration from "./environment";
import { ChangePasswordComponent } from "./pages/ChangePassword";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.state = {
      choosedMenuItem: [
        { label: "Home", icon: "fa-home", onClick: this.backToHome },
      ],
    };
  }

  

  handler(menu) {
    let menus = menu.split(",").map((e) => {
      switch (e) {
        case "Dashboard":
          return { label: e, icon: "fa-dashboard" };
        case "Management":
          return { label: e, icon: "fa-cog" };
        case "Controller":
          return { label: e, icon: "fa-microchip" };
        case "PointOfSales":
          return { label: e, icon: "fa-map-marker" };
        case "Users":
          return { label: e, icon: "fa-user-circle-o" };
        case "Commands":
          return { label: e, icon: "fa-terminal" };
        case "Advertisement":
          return { label: e, icon: "fa-solid fa-rectangle-ad" };
        case "Resources":
          return { label: e, icon: "fa-file" };
        default:
          return {};
      }
    });

    this.setState(() => ({
      choosedMenuItem: [
        { label: "Home", icon: "fa-home", onClick: this.backToHome },
        ...menus,
      ],
    }));
  }
  /**
   *
   * check if a token exist an its valid one
   *
   */
  checkTokenValidity = () => {
    if (this.props.history.location.pathname.startsWith("/change-password/")) {
      return;
    }
    if (this.props.history.location.pathname !== "/login") {
      if (localStorage.getItem("token") === null) {
        this.props.history.push("/login");
      } else {
        // check if token is valid
        let isValid =
          Date.now() <
          JSON.parse(
            atob(
              localStorage
                .getItem("token")
                .replace("Bearer", "")
                .trim()
                .split(".")[1]
            )
          ).exp *
            1000;
        if (
          isValid &&
          this.props.history.location.pathname !== "/login" &&
          this.props.history.location.pathname !== "/home"
        ) {
          this.props.history.push("/home");
        } else if (
          isValid &&
          this.props.history.location.pathname === "/home"
        ) {
        } else {
          // do nothing
          this.props.history.push("/login");
        }
      }
    }
  };

  toggleMenu = () => {
    document.querySelector(".ContentWrapper").classList.toggle("reduced");
  };

  backToHome = () => {
    // remove any active status if exist
    if (document.querySelector(".active") !== null) {
      document.querySelector(".active").classList.remove("active");
    }
    this.setState(() => ({
      choosedMenuItem: [{ label: "Home", icon: "fa-home" }],
    }));
  };

  render() {
    this.checkTokenValidity();
    return (
      <div className="App">
        <Loader />
        <Switch>
          <Route path="/change-password/:checksum" component={ChangePasswordComponent}  />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/home">
            {this.checkTokenValidity()}
            <div className="header-page">
              <Button className="menu-toggler" onClick={this.toggleMenu}>
                <i className="fa fa-bars" />
              </Button>
              <img
                style={{ paddingLeft: "5px", paddingBottom: "2px" }}
                alt=""
                src="SRCS.png"
                height="50"
              />
              <IconButton onClick={this.backToHome}>
                <img
                  style={{
                    alignSelf: "center",
                    marginTop: "10px",
                    marginLeft: "1px",
                    padding: "10px",
                    paddingLeft: "1px",
                    whiteSpace: "nowrap",
                  }}
                  alt="nopic"
                  src="../logosrcs1.png"
                  height="35"
                />
              </IconButton>

              <div className="notification-section">
                <NotificationComponent />
                <UserSectionComponent />
              </div>
            </div>
            <div className="ContentWrapper">
              <div className="HorizentalMenuDiv">
                <HorizenatlMenu chooseMenu={this.handler} />
              </div>
              <div className="Body">
              <div className="wip-panel" style={{display: this.props.wipEnabled ? "flex" : "none"}} >
                <div style={{display: "flex", flexDirection: "column" , width: "fix-content"}}>
                  <img src="/under-construction.png" height={220} width={220} /> 
                  <span style={{ fontWeight: "700"}}>UNDER CONSTRUCTION ...</span>
                </div> 
              </div>
                <Navigator nav={this.state.choosedMenuItem} />
                <ContentMenu
                  choosedMenu={this.state.choosedMenuItem}
                ></ContentMenu>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    );
  }
}

const Loader = (props) => {
  const hide = useSelector((state) => state.loadingReducer);

  return (
    <div
      className={hide ? "" : "hide"}
      id="ajaxLoader"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        justifyContent: "center",
        position: "fixed",
        zIndex: "10010",
        width: "100vw",
        height: "100vh",
        background: "#00000052",
      }}
    >
      <CircularProgress
        style={{
          width: "100px",
          height: "100px",
          margin: "auto",
          color: "#0000007d",
        }}
      />
    </div>
  );
};

const UserSectionComponent = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignSelf: "center",
      paddingLeft: 0,
      width: "50px",
      marginRight: "20px",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const logOut = () => {
    clearSession();
    window.location.reload();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div className={classes.root}>
        <div>
          <IconButton
            style={{ padding: "5px" }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <img
              id="userProfilePic"
              src={
                localStorage.getItem("userPic") !== "undefined" && localStorage.getItem("userPic") !== null
                  ? localStorage.getItem("userPic")
                  : "./defaultuser.png"
              }
              alt="nopic"
              height="40"
              width="40"
            />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleClose}>
                        Profile:{" "}
                        <span id="username">
                          {localStorage.getItem("user")}
                        </span>
                      </MenuItem>
                      <MenuItem onClick={logOut}>
                        <i className="fa fa-sign-out" /> Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </>
  );
};

const NotificationComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [badgeContent, setBadgeContent] = useState({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setInterval(() => {
      axios
        .get(
          Configuration.backend_api_url +
            "/controllers/health-status/notification",
          {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((resp) => {
          setBadgeContent(resp.data);
        });
    }, 30 * 1000);
  }, []);

  return (
    <div className="notification">
      <IconButton onClick={handleClick}>
        <Badge
          badgeContent={Object.keys(badgeContent).length}
          max={999}
          color="secondary"
        >
          <i className="fa fa-bell" />
        </Badge>
      </IconButton>
      <Menu id="notification-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        placement="bottom-start"
        onClose={handleClose}
      >
        <div className="notification-menu-content">
          <span className="header">Notification</span>
          {Object.keys(badgeContent).map((k) => (
            <MenuItem>
            <i className="fa fa-exclamation-triangle" />
              <span>{"Controller: " +
                k +
                " seem not to be OK, threshold: " +
                badgeContent[k]}</span>
            </MenuItem>
          ))}
          {Object.keys(badgeContent).length === 0 ? (
              <div className="no-msg-content">Nothing to highlight</div>
          ) : (
            ""
          )}
        </div>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => ({
  wipEnabled : state.wipEnabledReducer.enabled
})

export default withRouter(connect(mapStateToProps,null)(App));
