import { START_LOADING, STOP_LOADING,SELECT_CONTROLLER, SELECT_POINT_OF_SALES,SELECT_USERS } from "./actionTypes";



export function dispatchSelectCommand(command){
  return {
    type: "SELECT_COMMAND",
    command,
  };
}


export function enableLoaderAction() {
  return {
    type: START_LOADING,
  };
}

export function disableLoaderAction() {
  return {
    type: STOP_LOADING,
  };
}

export function dispatchSelectController(Controller) {
  return {
    type: SELECT_CONTROLLER,
    Controller,
  };
}
export function dispatchSelectPointOfSales(PointOfSales) {
  return {
    type: SELECT_POINT_OF_SALES,
    PointOfSales,
  };
}

export function dispatchSelectusers(users) {
  return {
    type: SELECT_USERS,
    users,
  };
}

export function closeModal(){
  return {
    type: "CLOSE_MODAL"
  }
}

export function openModal(){
  return {
    type: "OPEN_MODAL"
  }
}

