import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import buildUrl from "build-url";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  disableLoaderAction,
  dispatchSelectController,
  enableLoaderAction,
} from "../actions/globat-actions";
import ActionResultPanel from "../component/common/ActionResultPanel";
import DataTableToolbar from "../component/common/DatatableToolbar";
import SearchComponent from "../component/common/SearchComponent";
import EnhancerTatable from "../component/EnhancedTable";
import Configuration from "../environment";

import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  Tooltip,
} from "@material-ui/core";
import ExportActionComponent from "../component/common/ExportAction";
import FilterComponent from "../component/common/FilterComponent";
import deleteController from "../services/ControllerService";
import { showMessage } from "../services/MessageService";
import { toggleVisibility } from "../utils/effect.js";
import "./Controller.css";


/**
 *
 *  the initialState const of the datatable
 */
const initalState = {
  totalElements: 0,
  page: 0,
  pageSize: 10,
  content: [],
};

/**
 *
 * The Headers cells definitions
 */
const ControllerHeadCells = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  {
    id: "macAddresses",
    numeric: false,
    disablePadding: false,
    label: "Mac Addresses",
    className: "maxWidth130"
  },
  {
    id: "url",
    numeric: false,
    disablePadding: false,
    label: "URL",
  },
  {
    id: "controllerName",
    numeric: false,
    disablePadding: false,
    label: "Controller Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    type: "icon",
    label: "Status",
  },
];

let blankController = {
  id: "",
  macAddresses: "",
  url: "",
  controllerName: "",
  pos: { id: 0 },
};

const mapDispatchToprops = (dispatch) => {
  return {
    refreshDatatable: () => dispatch({ type: "REFRESH_DATATABLE" }),
    enableLoading: () => dispatch(enableLoaderAction()),
    disableLoading: () => dispatch(disableLoaderAction()),
    selectController: (Controller) =>
      dispatch(dispatchSelectController(Controller)),
  };
};

const fetchControllerByCriteria = async (criteria, page, pageSize) => {
  let noneBlaankedCriteria = {};
  if (criteria.macAddresses !== "" && criteria.macAddresses !== undefined) {
    noneBlaankedCriteria.macAddresses = criteria.macAddresses;
  }
  if (criteria.posName !== "" && criteria.posName !== undefined) {
    noneBlaankedCriteria.posName = criteria.posName;
  }
  if (criteria.url !== "" && criteria.url !== undefined) {
    noneBlaankedCriteria.url = criteria.url;
  }

  if (criteria.controllerName !== "" && criteria.controllerName !== undefined) {
    noneBlaankedCriteria.controllerName = criteria.controllerName;
  }

  if (criteria.posIdClient !== "" && criteria.posIdClient !== undefined) {
    noneBlaankedCriteria.posIdClient = criteria.posIdClient;
  }

  let uri = buildUrl(Configuration.backend_api_url, {
    path: "/controllers",
    queryParams: {
      ...noneBlaankedCriteria,
      page,
      pageSize,
    },
  });
  //+ encodeURIComponent(criteria);
  return await axios.get(uri, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
};

const EditController = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [Controller, setController] = useState(blankController);
  const [actionResult, setActionResult] = useState("");
  const [pointOfSales, setPointOfSales] = useState([]);
  const [pos, setPos] = useState({ id: "", name: "", clientName: "" });
  useEffect(() => {
    if (Controller.id !== "") {
      Promise.all([
        axios.get(
          Configuration.backend_api_url +
            `/controllers/${Controller.id}/labels`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        ),
        axios.get(
          Configuration.backend_api_url +
            "/point-of-sales?page=0&pageSize=1000",
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        ),
      ]).then((responses) => {
        setPointOfSales(responses[1].data.content);
        setLabels(responses[0].data);
      });
    }
  }, [pos.id, Controller.id]);

  let action =
    props.Controller.id === "" || props.Controller.id === undefined
      ? "ADD"
      : "EDIT";

  const dispatch = useDispatch();

  useEffect(() => {
    setController(props.Controller);
  }, [props.Controller]);

  const updateController = (Controller) => {
    props.enableLoading();
    if (pos.id !== "") {
      Controller.pos = pos;
    }

    axios
      .post(
        Configuration.backend_api_url + "/controllers",
        { ...Controller, labels },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            setActionResult("success");
            props.refreshDatatable();
          } else {
            setActionResult("failed");
          }
          props.disableLoading();
          showMessage(dispatch, 200, "Controller edited with success", "success")
          backToDataPanel();
        },
        (err) => {
          showMessage(dispatch, err.message);
          props.disableLoading();
        }
      );
  };

  const backToDataPanel = () => {
    props.selectController(blankController);
    setController(blankController);
    setPos({ id: "", name: "", clientName: "" });
    setActionResult("");
    toggleVisibility("dataPanel", "editPanel");
  };

  const changeHandler = (e) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "id":
        setController((prevState) => ({
          ...prevState,
          id: value,
        }));
        break;
      case "posId":
        setController((prevState) => ({
          ...prevState,
          pos: pointOfSales.filter((p) => p.id === value)[0],
        }));
        break;
      case "macAddresses":
        setController((prevState) => ({
          ...prevState,
          macAddresses: value,
        }));
        break;
      case "URL":
        setController((prevState) => ({
          ...prevState,
          url: value,
        }));
        break;
      case "controllerName":
        setController((prevState) => ({
          ...prevState,
          controllerName: value,
        }));
        break;
      case "pos.id":
        setPos({ ...pos, id: value });
        break;
      case "pos.name":
        setPos({ ...pos, name: value });
        break;
      case "pos.clientName":
        setPos({ ...pos, clientName: value });
        break;
      default:
        return;
    }
  };


  const sendCommand = (e) => {
    let command = {
      username: localStorage.getItem("user"),
      controllerName: props.Controller.controllerName,
    };
    switch (e.currentTarget.getAttribute("data-action")) {
      case "switchOnOff":
        command.commandAction =
          tvInfo.status === "on" ? "SWITCH_OFF" : "SWITCH_ON";
        break;
      case "activeSource":
        command.commandAction = "SET_ACTIVE_SOURCE";
        break;
      case "oneClickPlay":
        command.commandAction = "ONE_CLICK_PLAY";
        break;
    }
    dispatch({ type: "START_LOADING" });
    axios
      .post(Configuration.backend_api_url + "/commands", command, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((resp) => {
        console.log(resp);
        showMessage(dispatch, 200, "Command created", "info");
      })
      .catch((err) => {
        showMessage(dispatch, err.message);
      })
      .finally(() => {
        dispatch({ type: "STOP_LOADING" });
      });
  };

  const [tvInfo, setTvInfo] = useState({
    model: "Samsung",
    status: "standby",
    activeSource: "YES",
  });

  const [labels, setLabels] = useState("");

 


  return (
    <div style={{ marginTop: "50px" }}>
      <div id="editPanel" className="controller edit-panel hide">
        <ActionResultPanel actionResult={actionResult} />
        <span className="edit-panel-header">
          <span className="icon-button">
            <i 
              className="fa fa-arrow-left"
              tooltip="Back to Holders table"
              onClick={(e) => backToDataPanel()}
            ></i>
          </span>
          {action === "ADD" ? "Add Controller" : "Edit Controller"}
        </span>

        <div className="edit-panel-content">
          <FormControl>
            <InputLabel>ID</InputLabel>
            <Input
              name="id"
              value={Controller.id}
              disabled={action === "EDIT"}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>MAC ADDRESSES</InputLabel>
            <Input
              name="macAddresses"
              value={Controller.macAddresses}
              disabled
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>URL</InputLabel>
            <Tooltip title={Controller.url} placement="bottom">
              <Input
                name="URL"
                value={Controller.url}
                onChange={changeHandler}
              />
            </Tooltip>
          </FormControl>

          <FormControl>
            <InputLabel>Controller Name</InputLabel>
            <Input
              name="controllerName"
              disabled
              value={Controller.controllerName}
              onChange={changeHandler}
            />
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            padding: "0 0 15px 0",
            margin: "auto",
            alignItems: "flex-end",
          }}
        >
          <div className="newpos">
            <FormControl>
              <InputLabel>Sale Point Name</InputLabel>
              <Select
                style={{ minWidth: "300px" }}
                name="posId"
                value={Controller.pos.id}
                onChange={changeHandler}
              >
                {pointOfSales.map((pos) => (
                  <MenuItem value={pos.id}>{pos.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{display: "none"}}
          className={`display-panel ${
            (action === "ADD") | (Controller.type === "TV") ? "hide" : ""
          }`}
        >
          <div className="display-info">
            <span>TV information</span>
            <div className="display-info-content">
              <span>Model:</span>
              <span>{tvInfo.model}</span>

              <span>Status:</span>
              <span>{tvInfo.status}</span>

              <span>Active Source:</span>
              <span>{tvInfo.activeSource}</span>
            </div>
          </div>
          <div className="command-panel" >
            <span>Command</span>
            <MenuList>
              <MenuItem
                data-action="activeSource"
                onClick={sendCommand}
                disabled={tvInfo.activeSource === "YES"}
              >
                <i className="fa fa-arrow-right" style={{ color: "blue" }} />
                <span>Set Active Source</span>
              </MenuItem>
              <MenuItem data-action="switchOnOff" onClick={sendCommand}>
                <i
                  className="fa fa-power-off"
                  style={{ color: tvInfo.status === "on" ? "red" : "green" }}
                />
                <span>
                  {tvInfo.status === "on" ? "Switch Off" : "Switch On"}
                </span>
              </MenuItem>
              <MenuItem data-action="oneClickPlay" onClick={sendCommand}>
                <i className="fa fa-play" style={{ color: "green" }} />
                <span>Force Play</span>
              </MenuItem>
            </MenuList>
          </div>
        </div>


        <div className="action-panel">
          <Button
            variant="contained"
            className="form-action-button"
            onClick={(e) => updateController(Controller)}
          >
            Done
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={backToDataPanel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
});







const ControllerDataTable = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [data, setData] = useState(initalState);

  const dispatch = useDispatch();

  const editActionCallback = (row) => {
    toggleVisibility("dataPanel", "editPanel");
    props.selectController(row);
  };

  const changePaginationActionHandler = (criteria, page, pageSize) => {
    props.enableLoading();

    fetchControllerByCriteria(criteria, page, pageSize).then(
      (response) => {
        setData(response.data);
        props.disableLoading();
      },
      (err) => {
        showMessage(dispatch, err.message);
        props.disableLoading();
      }
    );
  };

  useEffect(() => {
    changePaginationActionHandler(
      props.searchCriteria,
      initalState.page,
      initalState.pageSize
    );
  }, [props.refresh, props.searchCriteria, , props.refresh]);

  const deleteControllerAction = (id, callback) => {
    dispatch({ type: "START_LOADING" });
    deleteController(id)
      .then((resp) => {
        callback();
        dispatch({ type: "STOP_LOADING" });
        dispatch({ type: "REFRESH_DATATABLE" });
        showMessage(
          dispatch,
          200,
          `Delete controllers : ${id} is sucess`,
          "success"
        );
      })
      .catch((err) => {
        dispatch({ type: "STOP_LOADING" });
        showMessage(dispatch, err.message);
      });
  };

  let additionalActions = [CommandAction];

  return (
    <div>
      <EnhancerTatable
        className="controller-table"
        headCells={ControllerHeadCells}
        data={data}
        page={data.number}
        rowId="id"
        pageSize={data.pageable === undefined ? 0 : data.pageable.pageSize}
        searchCriteria={props.searchCriteria}
        editAction={editActionCallback}
        entityName="Controller"
        deleteAction={deleteControllerAction}
        additionalActions={additionalActions}
        changePaginationAction={changePaginationActionHandler}
      />
    </div>
  );
});

const Controller = (props) => {
  const uploadedFileName = "amexcard-profileholder-file";

  const dispatch = useDispatch();

  const showExportMenuHandler = () => {
    dispatch({ type: "OPEN_MODAL" });
  };

  const uploadControllerFile = () => {
    let fd = new FormData();
    fd.append(
      uploadedFileName,
      document.querySelector(`input[name=${uploadedFileName}]`).files[0]
    );

    axios
      .post(
        Configuration.backend_api_url + "/persons/amexCardProfileHolderFeeds",
        fd,
        {
          headers: {
            "content-type": "mulitpart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(
        (resp) => {
          dispatch({ type: "REFRESH_DATATABLE" });
          dispatch({ type: "POPULATE_EXPORT_RESPONSE", payload: resp });
          dispatch({ type: "STOP_LOADING" });
        },
        (err) => {
          showMessage(dispatch, err.message);
          dispatch({ type: "STOP_LOADING" });
        }
      );

    // close modal
    dispatch({ type: "CLOSE_MODAL" });

    document.querySelectorAll(".notification-section span")[0].click();
    setTimeout(() => {
      document.querySelectorAll(".notification-section button")[2].click();
    }, 1500);
  };

  const [searchCriteria, setSearchCriteria] = useState({
    posName: "",
    url: "",
    controllerName: "",
    posIdClient: ""
  });

  const refresh = useSelector(
    (state) => state.DatatableReducer.refreshDatatable
  );
  const selectedController = useSelector(
    (state) => state.selectControllerReducer
  );

  dispatch({ type: "UPDATE_SEARCH_MODEL", payload: searchCriteria });

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <ExportActionComponent
        header="UPLOAD CONTROLLER"
        fileName={uploadedFileName}
        uploadAction={uploadControllerFile}
      />

      <div id="dataPanel">
        <div
          className={
            Object.keys(searchCriteria).find(
              (key) => searchCriteria[key] !== ""
            ) !== undefined
              ? "filter-section"
              : "filter-section hide"
          }
        >
          <span>Active Filters:</span>
          <div>
            <FilterComponent
              searchCriteria={searchCriteria}
              setSearchCriteriaCallback={setSearchCriteria}
            />
          </div>
        </div>

        <DataTableToolbar
          dataPanel="dataPanel"
          editPanel="editPanel"
          entityName="Controller"
          displayAdd={false}
          displayUpload={false}
          showUploadMenu={showExportMenuHandler}
        >
          <SearchComponent
            searchModel={searchCriteria}
            searchAction={setSearchCriteria}
          />
        </DataTableToolbar>

        <ControllerDataTable
          refresh={refresh}
          searchCriteria={searchCriteria}
        />
      </div>

      <EditController Controller={selectedController} />
      <CommandModal />
    </div>
  );
};

const CommandAction = ({ rowId }) => {
  const dispatch = useDispatch();

  const openModalCommand = () => {
    dispatch({ type: "OPEN_COMMAND_MODAL", payload: { controllerId: rowId } });
  };

  return (
    <IconButton onClick={openModalCommand}>
      <i className="fa fa-terminal"></i>
    </IconButton>
  );
};

const CommandModal = (props) => {
  const commandModalReducer = useSelector((state) => state.commandModalReducer);
  const [command, setCommand] = useState({});

  const dispatch = useDispatch();
  const onSelectCommandType = (e) => {
    switch (e.target.name) {
      case "command-type":
        setCommand({ ...command, commandAction: e.target.value });
    }
  };

  const saveCommand = () => {
    dispatch({ type: "START_LOADING" });
    axios
      .post(
        Configuration.backend_api_url + "/commands",
        { ...command, controllerId: commandModalReducer?.controllerId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((resp) => {
        showMessage(dispatch, 200, "Command created", "success");
        closeModal();
      })
      .catch((err) => {
        showMessage(dispatch, 500, "Cannot create Command", "error");
      })
      .finally(() => {
        dispatch({ type: "STOP_LOADING" });
      });
  };

  const closeModal = () => {
    dispatch({ type: "CLOSE_COMMAND_MODAL" });
  };

  return (
    <Dialog open={commandModalReducer?.open}>
      <div
        style={{
          width: "350px",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          rowGap: "20px",
        }}
        id="commandModal"
      >
        <h3 style={{ textAlign: "center" }}>Command Definition</h3>

        <FormControl>
          <InputLabel>Controller Id</InputLabel>
          <Input value={commandModalReducer?.controllerId} disabled></Input>
        </FormControl>
        <FormControl>
          <InputLabel>Command Action </InputLabel>
          <Select onChange={onSelectCommandType} name="command-type">
            <MenuItem value="SCREEN_SHOT">Screen Shot</MenuItem>
            <MenuItem value="OPEN_REMOTE_VIEW">Remote View</MenuItem>
          </Select>
        </FormControl>

        <div style={{ textAlign: "center" }}>
          <Button color="primary" onClick={saveCommand}>
            send Command
          </Button>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Controller;
