import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import ActionModal from "./ActionModal";
import MobileStepper from "@material-ui/core/MobileStepper";

import "./ExportAction.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { useDispatch } from "react-redux";


const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

export const UploadFlowComponent = (props) => {
  const clearMethod = () => {
    setFileValue("");
    setActiveStep(0);
  };


  let accept = props.accept !== undefined ? props.accept : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";



  const dispatch = useDispatch();

  const [fileValue, setFileValue] = useState("");

  const displayCorrectFileName = (fileName) => {
    let splittedFileName = fileName.replaceAll("\\", "/").split("/");
    return splittedFileName[splittedFileName.length - 1];
  };

  const classes = useStyles();
  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setFileValue("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const doUpload = () => {
    // handle promise function
    dispatch({
      type: "START_LOADING",
    });

    if (props.typeAction === "Promise") {
      props.uploadAction().then(() => {
        dispatch({ type: "STOP_LOADING" });
        dispatch({ type: "CLOSE_MODAL" });
        setFileValue("");
        setActiveStep(0);
      });
    } else {
      props.uploadAction();
      dispatch({ type: "STOP_LOADING" });
      dispatch({ type: "CLOSE_MODAL" });
      setFileValue("");
      setActiveStep(0);
    }
  };

  return (
    <>
      <MobileStepper
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={fileValue === "" || activeStep === 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0 || fileValue === ""}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />

      <div style={{ display: activeStep === 0 ? "block" : "none" }}>
        <UploadComponent
          updateActiveStep={setActiveStep}
          fileName={props.fileName}
          fileValue={fileValue}
          accept={accept}
          updateFileValue={setFileValue}
        > {props.children}</UploadComponent>
      </div>
      <div style={{ display: activeStep === 1 ? "block" : "none" }}>
        <div className="upload-step-panel">
          <img src="/xls-file-icon.png" style={{ width: "80px" }} alt="file-icon" />
          <span
            style={{
              visibility: fileValue === "" ? "hidden" : "visible",
              color: "green",
              whiteSpace: "noWrap",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {fileValue === ""
              ? "Choose a file"
              : displayCorrectFileName(fileValue)}
          </span>

          <div style={{ paddingTop: "15px" }}>
            <Button
              color="primary"
              onClick={doUpload}
              component="span"
              disabled={fileValue === "" ? true : false}
            >
              {" "}
              Start uploading
            </Button>
            <Button color="secondary" onClick={clearMethod}>
              Clear
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const ExportActionComponent = (props) => {
  return (
    <ActionModal clearMethod={() => {}}>
      <div className="panel-header">{props.header}</div>
      <UploadFlowComponent {...props} >
        {props.children}
       </UploadFlowComponent>
    </ActionModal>
  );
};

const UploadComponent = (props) => {
  const uploadFileTriggerAction = () => {
    uploadInputInputRef.current.click();
  };

  const handleChangeCallback = (event) => {
    props.updateFileValue(event.target.value);
    props.updateActiveStep(1);
  };

  const uploadInputInputRef = useRef(null);
  const dragAndDropDiv = useRef(null);



  const defineListener = useCallback(() => {
    const dropHandler = (e) => {
      e.stopPropagation();
      e.preventDefault();
  
      if (e.type === "drop" && e.dataTransfer.files[0].name.includes(".xlsx")) {
        props.updateFileValue(e.dataTransfer.files[0].name);
        props.updateActiveStep(1);
        uploadInputInputRef.current.files = e.dataTransfer.files;
      } else if (
        e.type === "drop" &&
        !e.dataTransfer.files[0].name.includes(".xlsx")
      ) {
        alert("please provide a xlsx file");
      }
    };
    dragAndDropDiv.current.addEventListener("dragenter", dropHandler);
    dragAndDropDiv.current.addEventListener("dragleave", dropHandler);
    dragAndDropDiv.current.addEventListener("dragover", dropHandler);
    dragAndDropDiv.current.addEventListener("drop", dropHandler);
  },[props]);



  useEffect(() => {
    // reset file whever a back command is set
    if (uploadInputInputRef.current !== undefined && props.fileValue === "") {
      uploadInputInputRef.current.value = "";
    }
    defineListener();
  }, [props.fileValue,defineListener]);

  return (
    <div
      className="select-file-panel"
      onClick={uploadFileTriggerAction}
      ref={dragAndDropDiv}
    >
      <img src="/upload.jpg" alt="upload img" style={{ width: "80px" }} />
      <span>Drag and drop or click to choose a file</span>
      <div>{props.children}</div>
      <input
        ref={uploadInputInputRef}
        type="file"
        accept={props.accept}
        name={props.fileName}
        //value={fileValue}
        onChange={handleChangeCallback}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ExportActionComponent;
