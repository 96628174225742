import React, { useEffect, useState } from "react";

import axios from "axios";
import buildUrl from "build-url";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  disableLoaderAction,
  dispatchSelectusers,
  enableLoaderAction,
} from "../actions/globat-actions";
import ActionResultPanel from "../component/common/ActionResultPanel";
import DataTableToolbar from "../component/common/DatatableToolbar";
import SearchComponent from "../component/common/SearchComponent";
import EnhancerTatable from "../component/EnhancedTable";
import Configuration from "../environment";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  Switch,
  Tab,
  Tabs,
} from "@material-ui/core";
import ExportActionComponent from "../component/common/ExportAction";
import FilterComponent from "../component/common/FilterComponent";
import { showMessage } from "../services/MessageService";
import deleteusers from "../services/usersService";
import { toggleVisibility } from "../utils/effect.js";
import "./Users.css";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "0",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 1),
  },
}));
/**
 *
 *  the initialState const of the datatable
 */
const initalState = {
  totalElements: 0,
  page: 0,
  pageSize: 10,
  content: [],
};

/**
 *
 * The Headers cells definitions
 */
const usersHeadCells = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "firstname",
    numeric: false,
    disablePadding: false,
    label: "Firstname",
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "lastname",
  },
];

let blankusers = {
  id: "",
  username: "",
  firstname: "",
  lastname: "",
  pic: "",
  email: "",
  roles: "",
  poss: [],
  gwebSso: false
};

const mapDispatchToprops = (dispatch) => {
  return {
    refreshDatatable: () => dispatch({ type: "REFRESH_DATATABLE" }),
    enableLoading: () => dispatch(enableLoaderAction()),
    disableLoading: () => dispatch(disableLoaderAction()),
    selectusers: (users) => dispatch(dispatchSelectusers(users)),
  };
};

const fetchusersByCriteria = async (criteria, page, pageSize) => {
  let noneBlaankedCriteria = {};
  if (criteria.username !== "" && criteria.usernmae !== undefined) {
    noneBlaankedCriteria.usernmae = criteria.usernmae;
  }
  if (criteria.firstname !== "" && criteria.firstname !== undefined) {
    noneBlaankedCriteria.firstname = criteria.firstname;
  }

  if (criteria.lastname !== "" && criteria.lastname !== undefined) {
    noneBlaankedCriteria.lastname = criteria.lastname;
  }

  let uri = buildUrl(Configuration.backend_api_url, {
    path: "/users",
    queryParams: {
      ...noneBlaankedCriteria,
      page,
      pageSize,
    },
  });
  //+ encodeURIComponent(criteria);
  return await axios.get(uri, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
};

const Editusers = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [users, setusers] = useState(blankusers);
  const [actionResult, setActionResult] = useState("");

  let action =
    props.users.id === "" || props.users.id === undefined ? "ADD" : "EDIT";

  const dispatch = useDispatch();

  useEffect(() => {
    setusers(props.users);
  }, [props.users]);

  const updateusers = (users) => {
    let userRoles = [];
    givenRoles.map((r, i) => {
      if (switches[i]) {
        userRoles.push("ROLE_" + r);
      }
    });

    if (userRoles.length === 0) {
      setValidationError("You must specify a role for the user");
      setOpen(true);
      return;
    }
    if (
      userRoles.includes("ROLE_MANAGER") &&
      choosedPoss.length === 0
    ) {
      setValidationError("You must choose a point of sale for manager");
      setOpen(true);
      return;
    } else {
      users.poss = choosedPoss;
    }
    users.roles = userRoles.reduce((r1, r2) => r1 + "," + r2);
    props.enableLoading();

    new Promise((resolve, reject) => {
        users.pic = imgSrc
        resolve();
    }).then(() => {
      axios
        .post(Configuration.backend_api_url + "/users", users, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              setActionResult("success");
              props.refreshDatatable();
            } else {
              setActionResult("failed");
            }
            setTimeout(() => {
              props.disableLoading();
              backToDataPanel();
            }, 2000);
          },
          (err) => {
            showMessage(
              dispatch,
              err.response.status,
              err.response.data,
              "error",
              5000
            );
            props.disableLoading();
          }
        );
    });
  };

  const backToDataPanel = () => {
    props.selectusers(blankusers);

    setusers(blankusers);

    setImageName("");
    if (document.getElementById("pic-displayer")) {
      document.getElementById("pic-displayer").src = undefined;
    }

    setActionResult("");
    toggleVisibility("dataPanel", "editPanel");
  };

  const changeHandler = (e) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "id":
        setusers((prevState) => ({
          ...prevState,
          id: value,
        }));
        break;
      case "email":
        setusers((prevState) => ({
          ...prevState,
          email: value,
        }));
        break;
      case "username":
        setusers((prevState) => ({
          ...prevState,
          username: value,
        }));
        break;
      case "firstname":
        setusers((prevState) => ({
          ...prevState,
          firstname: value,
        }));
        break;
      case "lastname":
        setusers((prevState) => ({
          ...prevState,
          lastname: value,
        }));  
        break;
      case "gwebSso":
        setusers((prevState) => ({
          ...prevState,
          gwebSso: e.target.checked,
        }));   
      default:
        return;
    }
  };

  const givenRoles = ["ADMIN", "MANAGER", "ADS_ADMIN"];

  const [switches, setSwitches] = useState(givenRoles.map((p) => false));
  const [searchPosName, setSearchPosName] = useState("");
  const [initialPoss, setInitialPoss] = useState([""]);

  const [choosedPoss, setChoosedPoss] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    let evalSwitch = [];
    if (users.roles !== undefined) {
      givenRoles.forEach((ro) => {
        evalSwitch.push(users.roles.split(",").some((r) => r.includes("ROLE_"  + ro)));
      });
      setSwitches(evalSwitch);
    }

    axios
      .get(
        Configuration.backend_api_url + "/point-of-sales?page=0&pageSize=1000",
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((resp) => {
        setInitialPoss(resp.data.content);
      });
    setChoosedPoss([...users.poss]);
    setImgSrc(users.pic);
  }, [users.roles]);

  const checkRole = (e, i) => {
    let s = [...switches]
    // case we check manager 
    if(i == 1 && e.target.checked){
      s = [false,false,false]
    }else if(s[1] && e.target.checked && i != 1){
       s[1] = false
    }
    s[i] = e.target.checked
    setSwitches(
      s
    );
  };

  const changeBox = (e, i, pos) => {
    if (e.target.checked) {
      setChoosedPoss([...choosedPoss, pos]);
    } else {
      setChoosedPoss(choosedPoss.filter((p) => p.id !== pos.id));
    }
  };

  const [imageName, setImageName] = useState("");

  const chooseImage = (e) => {
    document.querySelector("input[name='user-pic']").click();
  };

  const imageChange = (e) => {
    setImageName(e.currentTarget.files[0].name);
    var reader = new FileReader();
    reader.onload = function(){
      setImgSrc(this.result)
    }
    reader.readAsDataURL(e.currentTarget.files[0])
  };

  const searchForPos = (e) => {
    setSearchPosName(e.target.value);
    initialPoss.forEach((p) => {
      if (!p.name.includes(e.target.value)) {
        document.querySelector(`li[name='${p.id}']`).style.display = "none";
      } else {
        document.querySelector(`li[name='${p.id}']`).style.display = "block";
      }
    });
  };

  const [open, setOpen] = React.useState(false);
  const [validationError, setValidationError] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div id="editPanel" className="users edit-panel hide">
        <ActionResultPanel actionResult={actionResult} />
        <span className="edit-panel-header">
          <span className="icon-button">
            <i
              className="fa fa-arrow-left"
              tooltip="Back to Holders table"
              onClick={(e) => backToDataPanel()}
            ></i>
          </span>
          {action === "ADD" ? "Add users" : "Edit users"}
        </span>

        <div className="edit-panel-content">
          <FormControl>
            <InputLabel>ID</InputLabel>
            <Input
              name="id"
              value={users.id}
              disabled={action === "EDIT"}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Username</InputLabel>
            <Input
              name="username"
              value={users.username}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Email</InputLabel>
            <Input name="email" value={users.email} onChange={changeHandler} />
          </FormControl>

          <FormControl>
            <InputLabel>Fistname</InputLabel>
            <Input
              name="firstname"
              value={users.firstname}
              onChange={changeHandler}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Lastname</InputLabel>
            <Input
              name="lastname"
              value={users.lastname}
              onChange={changeHandler}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Google WebSSO</InputLabel>
            <Checkbox name="gwebSso" value={users.gwebSso} onChange={changeHandler}/>
          </FormControl>
        </div>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: "600px",
              margin: "auto",
            }}
          >
            <TabList onChange={onTabChange}>
              <Tab label="Picture" value={0} />
              <Tab label="Roles" value={1} />
              <Tab label="Point Of sales" value={2} />
            </TabList>
          </Box>
          <TabPanel value={0}>
            <FormControl className="upload-panel contained">
              <label>Choose your picture</label>
              <input
                type="file"
                name="user-pic"
                accept="image/*"
                style={{ visibility: "hidden" }}
                onChange={imageChange}
              />
              <div className="upload-component">
                <IconButton onClick={chooseImage}>
                  <i className="fa-image fa" />
                </IconButton>
                <div className="pic-displayer-panel">
                  <img
                    id="pic-displayer"
                    {...(imageName === "" &&
                    (users.pic === "" ||
                      users.pic === undefined ||
                      users.pic === null)
                      ? { style: { visibility: "hidden" } }
                      : {
                          style: { visibility: "visible" },
                          ...(imgSrc == null ? {} : { src: imgSrc }),
                        })}
                    width="60"
                    height="60"
                  />
                  <Input
                    value={imageName}
                    disabled
                    className={imageName === "" ? "hide" : ""}
                  />
                </div>
              </div>
            </FormControl>
          </TabPanel>
          <TabPanel value={1}>
            <FormControl className="roles-container contained">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {[0, 1 , 2].map((i) => (
                  <ListItem>
                    <span style={{ minWidth: "100px" }}>
                      {i === 0 ? "ADMIN" : ( i===1 ? "MANAGER" : "ADS_ADMIN")}
                    </span>
                    <Switch
                      edge="end"
                      checked={switches[i]}
                      onChange={(e) => checkRole(e, i)}
                    />
                  </ListItem>
                ))}
              </List>
            </FormControl>
          </TabPanel>
          <TabPanel value={2}>
            <div>
              <FormControl id="user-rel" className="contained">
                <label>Point of sales</label>
                <div className="pos-check-list">
                  <div className="pos-check-list-header">
                    <div>
                      <i className="fa fa-search" />
                      <Input
                        value={searchPosName}
                        style={{ fontSize: ".8em", fontWeight: "800" }}
                        onChange={searchForPos}
                      ></Input>
                    </div>
                  </div>

                  <div className="pos-check-list-content">
                    <ul>
                      {initialPoss.map((pos, i) => (
                        <li name={pos.id}>
                          <div className="pos-check-listItem">
                            <Checkbox
                              name={pos.id}
                              key={pos.id}
                              onChange={(e) => changeBox(e, i, pos)}
                              checked={
                                choosedPoss.filter((c) => c.id === pos.id)
                                  .length > 0
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />
                            <span>{pos.name}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </FormControl>
            </div>
          </TabPanel>
        </TabContext>

        <div className="action-panel">
          <Button
            variant="contained"
            className="form-action-button"
            onClick={(e) => updateusers(users)}
          >
            Done
          </Button>
          <Button
            color="secondary"
            variant="contained" style={{margin: "10px"}}
            onClick={backToDataPanel}
          >
            Cancel
          </Button>
          <Modal open={open} onClose={handleClose}>
            <Box className="error-modal">
              <h3>ERROR</h3>
              <div>
                <i className="fa fa-warning" />
                <span>{validationError}</span>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
});

const UsersDataTable = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [data, setData] = useState(initalState);

  const dispatch = useDispatch();

  const editActionCallback = (row) => {
    toggleVisibility("dataPanel", "editPanel");
    props.selectusers(row);
  };

  const changePaginationActionHandler = (criteria, page, pageSize) => {
    props.enableLoading();

    fetchusersByCriteria(criteria, page, pageSize).then(
      (response) => {
        setData(response.data);
        props.disableLoading();
      },
      (err) => {
        showMessage(dispatch, err.message);
        props.disableLoading();
      }
    );
  };

  useEffect(() => {
    changePaginationActionHandler(
      props.searchCriteria,
      initalState.page,
      initalState.pageSize
    );
  }, [props.refresh, props.searchCriteria, , props.refresh]);

  const deleteusersAction = (id, callback) => {
    dispatch({ type: "START_LOADING" });
    deleteusers(id)
      .then((resp) => {
        callback();
        dispatch({ type: "STOP_LOADING" });
        dispatch({ type: "REFRESH_DATATABLE" });
        showMessage(dispatch, 200, `Delete users : ${id} is sucess`, "success");
      })
      .catch((err) => {
        dispatch({ type: "STOP_LOADING" });
        showMessage(dispatch, err.message);
      });
  };

  return (
    <div>
      <EnhancerTatable
        headCells={usersHeadCells}
        data={data}
        page={data.number}
        rowId="id"
        pageSize={data.pageable === undefined ? 0 : data.pageable.pageSize}
        searchCriteria={props.searchCriteria}
        editAction={editActionCallback}
        entityName="users"
        deleteAction={deleteusersAction}
        changePaginationAction={changePaginationActionHandler}
      />
    </div>
  );
});

const Users = (props) => {
  const uploadedFileName = "amexcard-profileholder-file";

  const dispatch = useDispatch();

  const showExportMenuHandler = () => {
    dispatch({ type: "OPEN_MODAL" });
  };

  const uploadusersFile = () => {
    let fd = new FormData();
    fd.append(
      uploadedFileName,
      document.querySelector(`input[name=${uploadedFileName}]`).files[0]
    );

    axios
      .post(
        Configuration.backend_api_url + "/persons/amexCardProfileHolderFeeds",
        fd,
        {
          headers: {
            "content-type": "mulitpart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(
        (resp) => {
          dispatch({ type: "REFRESH_DATATABLE" });
          dispatch({ type: "POPULATE_EXPORT_RESPONSE", payload: resp });
          dispatch({ type: "STOP_LOADING" });
        },
        (err) => {
          showMessage(dispatch, err.message);
          dispatch({ type: "STOP_LOADING" });
        }
      );

    // close modal
    dispatch({ type: "CLOSE_MODAL" });

    document.querySelectorAll(".notification-section span")[0].click();
    setTimeout(() => {
      document.querySelectorAll(".notification-section button")[2].click();
    }, 1500);
  };

  const [searchCriteria, setSearchCriteria] = useState({
    username: "",
    firstname: "",
    lastname: "",
  });

  const refresh = useSelector(
    (state) => state.DatatableReducer.refreshDatatable
  );
  const selectedusers = useSelector((state) => state.selectusersReducer);

  dispatch({ type: "UPDATE_SEARCH_MODEL", payload: searchCriteria });

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <ExportActionComponent
        header="UPLOAD users"
        fileName={uploadedFileName}
        uploadAction={uploadusersFile}
      />

      <div id="dataPanel">
        <div
          className={
            Object.keys(searchCriteria).find(
              (key) => searchCriteria[key] !== ""
            ) !== undefined
              ? "filter-section"
              : "filter-section hide"
          }
        >
          <span>Active Filters:</span>
          <div>
            <FilterComponent
              searchCriteria={searchCriteria}
              setSearchCriteriaCallback={setSearchCriteria}
            />
          </div>
        </div>

        <DataTableToolbar
          dataPanel="dataPanel"
          editPanel="editPanel"
          entityName="users"
          displayUpload={false}
          showExportMenu={showExportMenuHandler}
        >
          <SearchComponent
            searchModel={searchCriteria}
            searchAction={setSearchCriteria}
          />
        </DataTableToolbar>

        <UsersDataTable refresh={refresh} searchCriteria={searchCriteria} />
      </div>

      <Editusers users={selectedusers} />
    </div>
  );
};

export default Users;
