import { Tooltip } from "@material-ui/core";
import React from "react";
import "./HomeComponent.css";

export const HomeComponent = (props) => {
  const openLink = (e) => {
    window.open(e.currentTarget.dataset.url);
  };

  return (
    <div className="home-component">
      <h2>Sesametech realtime content sharing</h2>
      <div>
        <h3>utils links</h3>
        <div className="home-link-section">
          <Tooltip title="Demande de support">
            <div onClick={openLink} data-url="https://trello.com/b/93Tp3gAU/srcs-support">
              <span></span>
              <img src="expenses-link-img.svg" alt="noicon" />
            </div>
          </Tooltip>

          <Tooltip title="Contactez nous !">
            <div>
              <span></span>
              <img src="support.svg" alt="noicon" />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
