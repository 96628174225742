import React, { useEffect, useState } from "react";

import axios from "axios";
import buildUrl from "build-url";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  disableLoaderAction, dispatchSelectPointOfSales, enableLoaderAction
} from "../actions/globat-actions";
import ActionResultPanel from "../component/common/ActionResultPanel";
import DataTableToolbar from "../component/common/DatatableToolbar";
import SearchComponent from "../component/common/SearchComponent";
import EnhancerTatable from "../component/EnhancedTable";
import Configuration from "../environment";

import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import tt from "@tomtom-international/web-sdk-maps";
import ExportActionComponent from "../component/common/ExportAction";
import FilterComponent from "../component/common/FilterComponent";
import { showMessage } from "../services/MessageService";
import deletePointOfSales from "../services/PointOfSalesService";
import { toggleVisibility } from "../utils/effect.js";

import "./PointOfSales.css";

/**
 *
 *  the initialState const of the datatable
 */
const initalState = {
  totalElements: 0,
  page: 0,
  pageSize: 10,
  content: [],
};

/**
 *
 * The Headers cells definitions
 */
const PointOfSalesHeadCells = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
];

let blankPointOfSales = {
  id: "",
  name: "",
  clientName: "",
  longitude: "",
  latitude: "",
  posIdClient: ""
};

const mapDispatchToprops = (dispatch) => {
  return {
    refreshDatatable: () => dispatch({ type: "REFRESH_DATATABLE" }),
    enableLoading: () => dispatch(enableLoaderAction()),
    disableLoading: () => dispatch(disableLoaderAction()),
    selectPointOfSales: (PointOfSales) =>
      dispatch(dispatchSelectPointOfSales(PointOfSales)),
  };
};

const fetchPointOfSalesByCriteria = async (criteria, page, pageSize) => {
  let noneBlaankedCriteria = {};
  if (criteria.id !== "" && criteria.id !== undefined) {
    noneBlaankedCriteria.id = criteria.id;
  }
  if (criteria.name !== "" && criteria.name !== undefined) {
    noneBlaankedCriteria.name = criteria.name;
  }

  if (criteria.clientName !== "" && criteria.clientName !== undefined) {
    noneBlaankedCriteria.clientName = criteria.clientName;
  }

  if (criteria.posIdClient !== "" && criteria.posIdClient !== undefined) {
    noneBlaankedCriteria.posIdClient = criteria.posIdClient;
  }

  let uri = buildUrl(Configuration.backend_api_url, {
    path: "/point-of-sales",
    queryParams: {
      ...noneBlaankedCriteria,
      page,
      pageSize,
    },
  });
  //+ encodeURIComponent(criteria);
  return await axios.get(uri, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
};

const EditPointOfSales = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [PointOfSales, setPointOfSales] = useState(blankPointOfSales);
  const [actionResult, setActionResult] = useState("");
  const [selectedClientName,setSelectedClientName] = useState(props.PointOfSales.clientName)

  const [clients,setClients] = useState([])

  let action =
    props.PointOfSales.id === "" || props.PointOfSales.id === undefined
      ? "ADD"
      : "EDIT";

  const dispatch = useDispatch();

  useEffect(() => {

    setSelectedClientName(props.PointOfSales.clientName)
    axios.get(Configuration.backend_api_url + "/clients",{
      headers: {
        Authorization: localStorage.getItem("token")
      }
    }).then(resp=> {
      setClients(resp.data)
    })
    setPointOfSales(props.PointOfSales);
  }, [props.PointOfSales, props.PointOfSales.clientName]);

  const updatePointOfSales = (PointOfSales) => {
    props.enableLoading();
    axios
      .post(Configuration.backend_api_url + "/point-of-sales", PointOfSales, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setActionResult("success");
            props.refreshDatatable();
          } else {
            setActionResult("failed");
          }
          setTimeout(() => {
            props.disableLoading();
            backToDataPanel();
          }, 2000);
        },
        (err) => {
          showMessage(dispatch, err.message);
          props.disableLoading();
        }
      );
  };

  const backToDataPanel = () => {
    props.selectPointOfSales(blankPointOfSales);
    setPointOfSales(blankPointOfSales);
    setSelectedClientName("")

    setActionResult("");
    toggleVisibility("dataPanel", "editPanel");
  };

  const changeHandler = (e) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "id":
        setPointOfSales((prevState) => ({
          ...prevState,
          id: value,
        }));
        break;
      case "name":
        setPointOfSales((prevState) => ({
          ...prevState,
          name: value,
        }));
        break;
      case "clientName":
        setSelectedClientName(value)
        setPointOfSales((prevState) => ({
          ...prevState,
          clientName: value,
        }));
        break;
      case "longitude":
        setPointOfSales((prevState) => ({
          ...prevState,
          longitude: value,
        }));
        break;
      case "latitude":
        setPointOfSales((prevState) => ({
          ...prevState,
          latitude: value,
        }));
        break;
      case "posIdClient":
          setPointOfSales((prevState) => ({
            ...prevState,
            posIdClient: value,
          }));
      default:
        return;
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div id="editPanel" className="edit-panel hide">
        <ActionResultPanel actionResult={actionResult} />
        <span className="edit-panel-header">
          <span className="icon-button">
            <i
              className="fa fa-arrow-left"
              tooltip="Back to Holders table"
              onClick={(e) => backToDataPanel()}
            ></i>
          </span>
          {action === "ADD" ? "Add PointOfSales" : "Edit PointOfSales"}
        </span>

        <div className="edit-panel-content">
          <FormControl>
            <InputLabel>ID</InputLabel>
            <Input
              name="id"
              disabled
              value={PointOfSales.id}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Name </InputLabel>
            <Input
              name="name"
              value={PointOfSales.name}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Client Name</InputLabel>
            <Select name="clientName" onChange={changeHandler} value={selectedClientName}>
              {clients.map(c=> <MenuItem key={c.id} value={c.clientName}>{c.clientName}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Longitude </InputLabel>
            <Input
              name="longitude"
              value={PointOfSales.longitude}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Latitude</InputLabel>
            <Input
              name="latitude"
              value={PointOfSales.latitude}
              onChange={changeHandler}
            />
          </FormControl>

          <FormControl>
            <InputLabel>ID client</InputLabel>
            <Input
              name="posIdClient"
              value={PointOfSales.posIdClient}
              onChange={changeHandler}
            />
          </FormControl>


        </div>
        <div className="action-panel">
          <Button
            variant="contained"
            className="form-action-button"
            onClick={(e) => updatePointOfSales(PointOfSales)}
          >
            Done
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={backToDataPanel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
});

const PointOfSalesDataTable = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [data, setData] = useState(initalState);
  const [displayMap, setDisplayMap] = useState(false);

  const toggleMap = (e) => {
    setDisplayMap(!displayMap);
    document.querySelector(".map-view > div").classList.toggle("active");
  };

  const dispatch = useDispatch();

  const editActionCallback = (row) => {
    toggleVisibility("dataPanel", "editPanel");
    props.selectPointOfSales(row);
  };

  const changePaginationActionHandler = (criteria, page, pageSize) => {
    props.enableLoading();

    fetchPointOfSalesByCriteria(criteria, page, pageSize).then(
      (response) => {
        setData(response.data);
        props.disableLoading();
      },
      (err) => {
        showMessage(dispatch, err.message);
        props.disableLoading();
      }
    );
  };

  useEffect(() => {
    changePaginationActionHandler(
      props.searchCriteria,
      initalState.page,
      initalState.pageSize
    );
  }, [props.refresh, props.searchCriteria,  props.refresh]);

  const deletePointOfSalesAction = (id, callback) => {
    dispatch({ type: "START_LOADING" });
    deletePointOfSales(id)
      .then((resp) => {
        callback();
        dispatch({ type: "STOP_LOADING" });
        dispatch({ type: "REFRESH_DATATABLE" });
        showMessage(dispatch,200,`Delete point of sales : ${id} is sucess`, "success")

      })
      .catch((err) => {
        dispatch({ type: "STOP_LOADING" });
        showMessage(dispatch, err.message);
      });
  };

  return (
    <div>
      <div className="map-view">
        <div>
          <Tooltip title="Map view">
            <IconButton onClick={toggleMap}>
              <i className="fa fa-map" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <EnhancerTatable
        hide={displayMap}
        headCells={PointOfSalesHeadCells}
        data={data}
        page={data.number}
        rowId="id"
        pageSize={data.pageable === undefined ? 0 : data.pageable.pageSize}
        searchCriteria={props.searchCriteria}
        editAction={editActionCallback}
        entityName="PointOfSales"
        deleteAction={deletePointOfSalesAction}
        changePaginationAction={changePaginationActionHandler}
      />
      <MapComponent display={displayMap} poss={data} />
    </div>
  );
});

const PointOfSales = (props) => {
  const uploadedFileName = "point-of-sales-file";

  const dispatch = useDispatch();

  const showExportMenuHandler = () => {
    dispatch({ type: "OPEN_MODAL" });
  };

  const uploadPointOfSalesFile = () => {
    let fd = new FormData();
    fd.append(
      uploadedFileName,
      document.querySelector(`input[name=${uploadedFileName}]`).files[0]
    );

    axios
      .post(
        Configuration.backend_api_url + "/point-of-sales/upload",
        fd,
        {
          headers: {
            "content-type": "mulitpart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(
        (resp) => {
          dispatch({ type: "REFRESH_DATATABLE" });
          dispatch({ type: "POPULATE_EXPORT_RESPONSE", payload: resp });
          dispatch({ type: "STOP_LOADING" });
        },
        (err) => {
          showMessage(dispatch, err.message);
          dispatch({ type: "STOP_LOADING" });
        }
      );

    // close modal
    dispatch({ type: "CLOSE_MODAL" });

    document.querySelectorAll(".notification-section span")[0].click();
    setTimeout(() => {
      document.querySelectorAll(".notification-section button")[2].click();
    }, 1500);
  };

  const [searchCriteria, setSearchCriteria] = useState({
    id: "",
    name: "",
    clientName: "",
    posIdClient: ""
  });

  const refresh = useSelector(
    (state) => state.DatatableReducer.refreshDatatable
  );
  const selectedPointOfSales = useSelector(
    (state) => state.selectPointOfSalesReducer
  );

  dispatch({ type: "UPDATE_SEARCH_MODEL", payload: searchCriteria });

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <ExportActionComponent
        header="UPLOAD POINT OF SALES"
        fileName={uploadedFileName}
        accept=".csv"
        uploadAction={uploadPointOfSalesFile}
      />

      <div id="dataPanel">
        <div
          className={
            Object.keys(searchCriteria).find(
              (key) => searchCriteria[key] !== ""
            ) !== undefined
              ? "filter-section"
              : "filter-section hide"
          }
        >
          <span>Active Filters:</span>
          <div>
            <FilterComponent
              searchCriteria={searchCriteria}
              setSearchCriteriaCallback={setSearchCriteria}
            />
          </div>
        </div>

        <DataTableToolbar
          dataPanel="dataPanel"
          editPanel="editPanel"
          entityName="PointOfSales"
          showUploadMenu={showExportMenuHandler}
        >
          <SearchComponent
            searchModel={searchCriteria}
            searchAction={setSearchCriteria}
          />
        </DataTableToolbar>

        <PointOfSalesDataTable
          refresh={refresh}
          searchCriteria={searchCriteria}
        />
      </div>

      <EditPointOfSales PointOfSales={selectedPointOfSales} />
    </div>
  );
};

const MapComponent = (props) => {
  useEffect(() => {
    if (!props.display) {
      document.getElementById("map").style.display = "none";
      return;
    } else {
      document.getElementById("map").style.display = "block";
    }

    const map = tt.map({
      key: "40qhWbTVI87fvwajIGbf9gKjND1ISM3D",
      container: "map",
      center: { lng: -9.354160402246976, lat: 28.394101729832087 },
      zoom: 3.8,
    });

    const controllerEntry = (k) => {
      if (k.upRatio > 0.5) {
        return `<span>${k.controllerName}</span><span><i class="fa fa-check" style="color: green" ></i></span>`
      } else {
        return `<span>${k.controllerName}</span><span><i class="fa fa-times" style="color: red"  ></i></span>`
      }
    };

    let controllersStatus;
    axios
      .get(
        Configuration.backend_api_url +
          "/point-of-sales/controllers/status?ids=" +
          props.poss.content.map((p) => p.id),
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((resp) => {
        controllersStatus = resp.data;
        props.poss.content.forEach((p) => {
          const popupContent = `
        <div class="map-popup">
          <div class="pos-details">
            <span>Pos name :</span><span> ${p.name}</span>
            <span>Client name :</span><span> ${p.clientName}</span>
          </div>
          <div class="controller-kpi">
            <span>Controller</span>
            <span>Status</span>
            ${controllersStatus.length === 0 ? "" : controllersStatus.filter(s=> s.posId === p.id).map((k) => controllerEntry(k)).length === 0 ? "" : 
            controllersStatus.filter(s=> s.posId === p.id).map((k) => controllerEntry(k)).reduce((a,b)=> a+b)}
          </div>
        </div>`;
          if(p.longitude !== undefined && p.latitude !== undefined){
          new tt.Marker({ color: "red" })
            .setLngLat({ lng: p.longitude, lat: p.latitude })
            .setPopup(new tt.Popup({ closeButton: true }).setHTML(popupContent))
            .addTo(map);
          }
        });
      });
  }, [props.display, props.poss]);

  return <div id="map"></div>;
};

export default PointOfSales;
