import React,{useState} from "react";
import { useDispatch } from "react-redux";
import Command from "../management/Command";
import Controller from "../management/Controller";
import PointOfSales from "../management/PointOfSales";
import Users from "../management/Users";
import DashboardComponent from "./DashboardComponent";
import { HomeComponent } from "./home/HomeComponent";
import PubliciteComponent from "./PubliciteComponent";




const ContentMenu = ({choosedMenu}) => {
  const dispatcher = useDispatch();
  switch (choosedMenu.map(e=>e.label).reduce((e,e1)=> e + "," + e1)) {
    case "Home": 
    return <HomeComponent />
    case "Home,Dashboard":
      dispatcher({type: "WIP_ENABLED"})
      return <DashboardComponent/>
    case "Home,Management,Controller":
      dispatcher({type: "WIP_DISABLED"})
      return <Controller />;
    case "Home,Management,PointOfSales":
      dispatcher({type: "WIP_DISABLED"})
      return <PointOfSales />;
    case "Home,Management,Users":
      dispatcher({type: "WIP_DISABLED"})
      return <Users />;
    case "Home,Management,Commands":
      dispatcher({type: "WIP_DISABLED"})
        return <Command />; 
    case "Home,Advertisement,Resources":
      dispatcher({type: "WIP_DISABLED"})
      return <PubliciteComponent />;
  default:
  return (
    <div />
  );
  }
};

export default ContentMenu;
