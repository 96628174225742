import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toggleVisibility } from "../../utils/effect";
import "./DataTableToolbar.css";

const DataTableToolbar = (props) => {
  const performedAction = useSelector(
    (state) => state.performSearchActionReducer
  );




  let searchEnabled = false;

  if (performedAction !== null && performedAction !== undefined) {
    searchEnabled = Object.keys(performedAction).find(
        (key) => performedAction[key] !== ""
      ) !== undefined;
  }

  const toggleSearchPanelVisibility = () => {
    document.getElementsByClassName("search-panel")[0].classList.toggle("hide");
  };


  const [openExportDialog,setOpenExportDialog] = useState(0)

  const exportDataAction = ()=>{
    setOpenExportDialog(openExportDialog +1)
  }

  const rows = useSelector(state=> state.DatatableReducer.data.contents)





  return (
    <div className="datatable-toolbar">
      <Button
        className={
          props.displayAdd === undefined || props.displayAdd
            ? ""
            : "DisplayNone"
        }
        onClick={(e) => toggleVisibility(props.dataPanel, props.editPanel)}
      >
        <i className="fa fa-plus" style={{ paddingRight: "5px" }} />
        <span>Add</span>
      </Button>

      <div
        style={{ position: "relative", paddingLeft: "10px", display: "flex" }}
      >
        <Button
          onClick={toggleSearchPanelVisibility}
          className={searchEnabled ? "red-color" : ""}
        >
          <i className="fa fa-search" style={{ paddingRight: "5px" }} />
          <span>Search</span>
        </Button>
        <div className="search-panel hide">{props.children}</div>
      </div>

      <div
        style={{ flexBasis: "100%", textAlign: "right" }}
      >
        <Button
          onClick={props.showUploadMenu}
          style={{
            display:
              props.displayUpload === undefined || props.displayUpload?   "inline-block" : "none",
          }}
        >
          <i className="fa fa-upload" style={{ paddingRight: "5px" }}></i>
          <span>Upload</span>
        </Button>
 
        <Tooltip title="Export search result in CSV format">
          <Button
                id="exprot-button-id"
                disabled={rows === undefined || rows.length === 0}
                onClick={exportDataAction}
                  className={
                    props.displayExport === undefined || props.displayExport
                      ? ""
                      : "DisplayNone"
                  }
                >
                  <i className="fa fa-download" style={{ paddingRight: "5px" }}></i>
                  <span>export</span>
            </Button>
        </Tooltip>
      </div>
      <ExportDialog open={openExportDialog} entityName={props.entityName} />
    </div>
  );
};



const ExportDialog = (props) => {
  const [open, setOpen] = useState(props.open >0);


  const entityName = useSelector(state=> state.DatatableReducer.data.entityName)
  const headCells = useSelector(state=> state.DatatableReducer.data.headers)
  const rows = useSelector(state=> state.DatatableReducer.data.contents)



  const handleClose = ()=>{
    setOpen(false)
  }

  const agreedAction = ()=>{
      let headerLine = headCells
        .map((c) => c.label)
        .reduce((c1, c2) => c1 + ";" + c2);
  
      let content = rows
        .map((row) =>
          headCells.map((c) => row[c.id]).reduce((c1, c2) => c1 + ";" + c2)
        )
        .reduce((l, l1) => l + "\n" + l1);
  
      let fileContent = headerLine + "\n" + content;
  
      const blob = new Blob([fileContent], {
        type: "text/csv",
        encoding: "UTF-8",
      });
  
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `export-${entityName}.csv`;
      link.click();
      setOpen(false)
  }

  useEffect(()=>{
    setOpen(props.open >0)
  },[props.open])

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">Export {entityName}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are about exporting data on CSV format
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Disagree
        </Button>
        <Button onClick={agreedAction} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};




export default DataTableToolbar;
