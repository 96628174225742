import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialogComponent from "./common/DeleteDialogComponent";
import { useDispatch } from "react-redux";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array === undefined) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className={
            props.displayCheck === undefined || props.displayCheck
              ? ""
              : "DisplayNone"
          }
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell style={{ padding: 0 }}></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      <Tooltip
        title="Delete"
        style={{ display: numSelected > 0 ? "block" : "none" }}
      >
        <IconButton
          aria-label="delete"
          onClick={() => props.deleteAllAction(props.selected)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  deleteAllAction: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    textAlign: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(props.rowId);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(props.page);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.pageSize);
  let rows = props.data.content;
  let {additionalActions} = props 

  
  const dispatch = useDispatch();

  useEffect(() => {
    setRowsPerPage(props.pageSize);
    setPage(props.page);
    dispatch({
      type: "DATA_TABLE_CONTENT",
      data: {
        entityName: props.entityName,
        headers: props.headCells,
        contents: props.data.content,
      },
    });
  }, [props.pageSize, props.page, props.data]);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[props.rowId]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.changePaginationAction(props.searchCriteria, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    props.changePaginationAction(props.searchCriteria, 0, event.target.value);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const onClickAction = (e) => {
    if (props.onClickCallBack === undefined) {
    } else {
      props.onClickCallBack(e);
    }
  };

  const [openStatus, setOpenStatus] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const openDeleteDialog = (ids) => {
    setOpenStatus(true);
    setSelectedIds(Array.of(ids));
  };

  const deleteAllAction = (ids) => {
    setOpenStatus(true);
    setSelectedIds(ids);
  };

  const clearSelectedItem = () => {
    setOpenStatus(false);
    setSelected([]);
  };

  useEffect(() => {
    // set staus icons
    let statusIndex = 0;
    document.querySelectorAll("th").forEach((e, index) => {
      if (e.innerText === "Status") {
        statusIndex = index;
      }
    });

    console.log(statusIndex);
    if (statusIndex > 0) {
      document.querySelectorAll("td").forEach((e, index) => {
        if( e.innerText === "UP" || e.querySelector("i.fa-check") !== null){
          e.innerHTML ='<i class="fa fa-check" style="color: green" />'
        }else if(e.innerText === "DOWN" || e.querySelector("i.fa-times") !== null){
          e.innerHTML ='<i class="fa fa-times" style="color: red"  />'
        }        
      });
    }
  });

  const enhancedComp = (C, rowId) => (props) => {
    return <C {...props} rowId={rowId} />
  }

  
  return (
    <div
      className={classes.root + " " + props.className}
      style={props.hide ? { display: "none" } : { display: "block" }}
    >
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          deleteAllAction={deleteAllAction}
          numSelected={selected.length}
          selected={selected}
          rowCount={
            props.data.contents === undefined ? 0 : props.data.contents.length
          }
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              displayCheck={props.displayCheck}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              headCells={props.headCells}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={
                props.data.content === undefined
                  ? 0
                  : props.data.content.length
              }
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(0, rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row[props.rowId]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[props.rowId]}
                      selected={isItemSelected}
                      onClick={() => onClickAction(row)}
                    >
                      <TableCell
                        padding="checkbox"
                        className={
                          props.displayCheck === undefined || props.displayCheck
                            ? ""
                            : "DisplayNone"
                        }
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleClick(event, row[props.rowId])
                          }
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>

                      {props.headCells.map((cell, index) => {
                        return (
                          <TableCell
                            align="left" className={cell.className}
                            key={`${row[props.rowId]}-${index}`}
                          >
                            {typeof row[cell.id] === "boolean" ? (
                              <Checkbox
                                checked={row[cell.id]}
                                disabled
                                style={{ padding: 0 }}
                              />
                            ) : (
                              row[cell.id]
                            )}
                          </TableCell>
                        );
                      })}

                      <TableCell
                        style={{
                          display: "flex",
                          padding: 0,
                          justifyItems: "center",
                        }}
                      >
                        <Tooltip title="Edit/Info">
                          <IconButton
                            className={
                              props.displayEdit === undefined ||
                              props.displayEdit
                                ? ""
                                : "DisplayNone"
                            }
                            aria-label="edit"
                            style={{ width: "45px" }}
                            onClick={(e) => props.editAction(row)}
                          >
                            <i className="fa fa-edit" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            className={
                              props.displayDelete === undefined ||
                              props.displayDelete
                                ? ""
                                : "DisplayNone"
                            }
                            onClick={() => openDeleteDialog(row[props.rowId])}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        {additionalActions?.map(e=> {const Enh = enhancedComp(e,row[props.rowId])
                           return <Enh />
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={props.data.totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteDialogComponent
        openStatus={openStatus}
        title={`Delete ${props.entityName} ?`}
        content={`Are you sure about delete  ${props.entityName} with id : ${selectedIds}`}
        closeCallback={()=>setOpenStatus(false)}
        agreedCallbak={() =>
          props.deleteAction(
            selectedIds.reduce((a, b) => a + "," + b),
            clearSelectedItem
          )
        }
      />
    </div>
  );
}
