const messageReducer = (state = {hide:true}, action) => {
  switch (action.type) {
    case "DISPATCH_MESSAGE":
      state = action.payload;
      return state;
    case "FADE_MESSAGE":
      state = {hide: true} ;
      return state;
    default:
      return state;
  }
};

export default messageReducer;
