const PerformSearchActionReducer = (state = null, action) => {
  switch (action.type) {
    case "UPDATE_SEARCH_MODEL":
      state = action.payload;
      return state;

    case "CLEAN_SEARCH_MODEL":
      let os = {...state}
      Object.keys(os).forEach((key) => {
        os[key] = "";
      });
      state= os
      return state;

    case "UPDATE_SEARCH_MODEL_BY_EVENT":
      let o = {};
      o[action.payload.key] = action.payload.value;
      state = { ...state, ...o };
      return state;
    default:
      return state;
  }
};

export default PerformSearchActionReducer;
