


/**
 * function to toggle visibily use the hide class from global css
 * @author abourouh
 * @param {*} idFirstPanel the first panel
 * @param {*} idSecondPanel the second panel
 */
export function toggleVisibility(idFirstPanel,idSecondPanel){
    let firstPanel = document.getElementById(idFirstPanel) ;
    firstPanel.classList.toggle("hide") ; 

    let secondPanel = document.getElementById(idSecondPanel) ;
    secondPanel.classList.toggle("hide") ; 
}

