import React from "react";

const FilterComponent = ({ searchCriteria, setSearchCriteriaCallback }) => {
  const removeCriteriaField = (key) => {
    setSearchCriteriaCallback({
      ...searchCriteria,
      [key]: "",
    });
  };

  return Object.keys(searchCriteria).map((key) => {
    if (searchCriteria[key] !== "") {
      return (
        <div key={key}>
          <span>{key}:</span>
          <span style={{ textIndent: "nowrap" }}>{searchCriteria[key]}</span>
          <i
            className="fa fa-times-circle"
            onClick={() => removeCriteriaField(key)}
          />
        </div>
      );
    }
  });
};

export default FilterComponent;
