import axios from "axios";
import Configuarion from "../environment";
import { showMessage } from "./MessageService";



const deleteusers = async (PdeleteusersId) => {
    
    await axios.delete(Configuarion.backend_api_url + "/users", 
    {
        headers: {
            Authorization : localStorage.getItem("token")
        } , 
        params: {
          ids: PdeleteusersId
        }
    });
    
}


export default deleteusers ;