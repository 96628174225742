import React, { useEffect, useState } from "react";
import { Button, Input, InputLabel, TextField } from "@material-ui/core";
import axios from "axios";
import Configuration from "../environment";
import { showMessage } from "../services/MessageService";
import { useDispatch } from "react-redux";
import "./ChangePassword.css";

const ChangePasswordComponent = (props) => {
  let { checksum } = props.match.params;

  const [data,setData] = useState({}) ; 

  useEffect(()=>{
    axios.get(Configuration.backend_api_url + `/users/change-password/verify-checksum?checksum=${checksum}`)
    .then(resp=> {
      let d = {}
      Buffer.from(checksum,'base64').toString('utf8').split(";")
      .map(p=> {
        let keyValueArray = p.split("=")
        d[keyValueArray[0]] =  keyValueArray[1]});
        setData({...d, error: false})
    }).catch(err => {
      showMessage(dispatch, err.response.status, "cannot verify checksum", "error")
      setData({error: true, errorHelper: (err.response.status == 400 ?
      "DEFINITION/Changement de Mot de passe déja effectué" : 
      "Un problème est survenu, merci de contacter l'administrateur")})
    })
  },[])
  

  const [passwordInputs, SetPasswordInputs] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errorMatch, setErrorMatch] = useState({
    enabled: false,
    helperText: "",
  });
  const dispatch = useDispatch();



  const onChangePassword = (e) => {
    SetPasswordInputs({ ...passwordInputs, password: e.target.value });
    if (
      e.target.value !== "" &&
      e.target.value !== passwordInputs.confirmPassword
    ) {
      setErrorMatch({
        enabled: true,
        helperText: "Mot de passes ne sont pas identiques",
      });
    } else {
      setErrorMatch({
        enabled: false,
        helperText: "",
      });
    }
  };

  const onChangeVerifPassword = (e) => {
    SetPasswordInputs({ ...passwordInputs, confirmPassword: e.target.value });
    if (
      passwordInputs.password !== "" &&
      e.target.value !== "" &&
      e.target.value !== passwordInputs.password
    ) {
      setErrorMatch({
        enabled: true,
        helperText: "Mot de passes ne sont pas identiques",
      });
    } else {
      setErrorMatch({
        enabled: false,
        helperText: "",
      });
    }
  };

  const definePassword = () => {
    axios
      .post(
        Configuration.backend_api_url + `/users/change-password/${checksum}`,
        passwordInputs
      )
      .then((resp) => {
        showMessage(
          dispatch,
          resp.status,
          "Definition de mot de passe reussite", "success"
        );
        window.location = "/login"
      })
      .catch((err) => {
        showMessage(
          dispatch,
          err.response.status,
          "Impossible de definir le mot de passe",
          "error"
        );
      });
  };

  const cleanInputs = () => {
    SetPasswordInputs({
      password: "",
      confirmPassword: "",
    });
  };

  return (
    <div class="change-password" >
      { data.error == undefined ? (<></>) : (
      <>      
      <div style={data?.error ? {display: "grid"} : {display: "none"}}>
        <h3> {data.errorHelper}</h3>
      </div>
      <div style={data?.error ? {display: "none"} : {display: "grid"}}>
        <h3>DEFINITION/Changement de mot de passe</h3>
        <div>
          <div>
            <InputLabel>Nom d'utilisateur</InputLabel>
            <Input type="text" value={data.username} readOnly />

            <InputLabel>Mot de passe</InputLabel>
            <Input
              type="password"
              value={passwordInputs.password}
              onChange={onChangePassword}
            />

            <InputLabel>Confirmation de mot de passe</InputLabel>
            <TextField
              error={errorMatch.enabled}
              helperText={errorMatch.helperText}
              type="password"
              value={passwordInputs.confirmPassword}
              onChange={onChangeVerifPassword}
            />
          </div>
          <div className="action-panel">
            <Button
              color="primary"
              variant="contained"
              onClick={definePassword}
              disabled={errorMatch.enabled || passwordInputs.password === ""}
            >
              Valider
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onChange={cleanInputs}
            >
              Annuler
            </Button>
          </div>
        </div>
      </div>
      </>)}
    </div>
  );
};

export { ChangePasswordComponent };
