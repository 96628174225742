import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Callback, makeUserManager } from "react-oidc";
import { Log } from "oidc-client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore } from "redux";
import App from "./App";
import ErrorMessage from "./component/common/Message";
import Configuration from "./environment";
import "./index.css";
import reducer from "./reducers";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";


let store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

window.store = store;



const userManagerConfig = {
  authority: Configuration.oidc_authority,
  client_id: Configuration.oidc_client_id,
  redirect_uri: Configuration.oidc_redirect_uri,
  metadata: {
    issuer: "https://accounts.google.com",
    userinfo_endpoint: "https://openidconnect.googleapis.com/v1/userinfo",
    authorization_endpoint: "https://accounts.google.com/o/oauth2/v2/auth",
    token_endpoint: Configuration.backend_api_url + "/token",
  },
  response_type: "code",
  scope: "openid email",
};
let userManager = makeUserManager(userManagerConfig);
window.userManager = userManager;
// put metadata

Log.logger = console;

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            path="/login/callback"
            render={(routeProps) => (
              <Callback
                onSuccess={(user) => {
                  // `user.state` will reflect the state that was passed in via signinArgs.
                  let idToken = JSON.parse(
                    sessionStorage.getItem(
                      "oidc.user:" +
                        Configuration.oidc_authority +
                        ":" +
                        Configuration.oidc_client_id
                    )
                  )["id_token"];
                  axios
                    .post(
                      Configuration.backend_api_url + "/validate-token",
                      null,
                      {
                        headers: { idToken },
                      }
                    )
                    .then((resp) => {
                      localStorage.setItem(
                        "token",
                        "Bearer " + resp.data.authorization
                      );
                      localStorage.setItem("user", resp.data.username);
                      if (resp.data.userPic !== undefined) {
                        localStorage.setItem(
                          "userPic",
                          atob(resp.data.userPic)
                        );
                      }
                      routeProps.history.push("/home");
                    });
                }}
                userManager={userManager}
              />
            )}
          />
          <>
            <ErrorMessage />
            <App />
          </>
        </Switch>
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
