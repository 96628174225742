const ModalReducer = (state = false, action) => {
  switch (action.type) {
    case "CLOSE_MODAL":
      return (state = false);
    case "OPEN_MODAL":
      return (state = true);
    default:
      return state;
  }
};

export default ModalReducer;
