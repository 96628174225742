import { Button, Menu, MenuItem, Modal, Select } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Configuration from "../environment";
import { showMessage } from "../services/MessageService";

import "./PubliciteComponent.css";

const PubliciteComponent = (props) => {
  const [sessionId, setSessionId] = useState("");
  const [fileName, setFileName] = useState("");
  const [rsType, setRsType] = useState("")

  const [open,setOpen] = useState(false)

  const fRef = useRef();

  /**
   * upload pubFile by sessionId
   * @param {*} file
   * @returns
   */
  const uploadPubFiles = async (file, rsType) => {
    if(file?.files === null || file?.files.length === 0){
      alert("please select a file");
      return Promise.reject({error: "need to choose a file"})
    }
    let fd = new FormData();
    rsType === "carousel" ? Array.from(file.files).forEach((f,index)=> fd.append(`pub-rs-${index}`,f)) : fd.append("pub-rs", file.files[0]);

    return axios.post(Configuration.backend_api_url + "/pubs/rs", fd, {
      headers: {
        sessionId,
        Authorization: localStorage.getItem("token"),
      },
    });
  };

  const initSession = async () => {
    return await axios.post(
      Configuration.backend_api_url + "/pubs/init-session",
      null,
      {
        headers: {
          accept: "text/event-stream",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  };

  const visualiser = async (sessionId, fileName,rsType) => {
    return axios.post(
      Configuration.backend_api_url + "/pubs/visualiser",
      { sessionId, fileName, rsType },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  };

  //const wcompiler = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "START_LOADING" });
    initSession()
      .then((resp) => {
        setSessionId(resp.data);
        /*
        let height =
          window.innerHeight - 50 - wcompiler.current.getBoundingClientRect().y;
        let width =
          window.innerWidth - 210 - wcompiler.current.getBoundingClientRect().x;

        wcompiler.current.style.height = height + "px";
        wcompiler.current.style.width = width + "px";
        let frame = wcompiler.current.querySelector("iframe");
        console.log(
          `${Number.parseInt(
            (Number.parseInt(wcompiler.current.style.width) -
              frame.getBoundingClientRect().x) /
              2
          )}px 0`
        );

        frame.style.transformOrigin = `${Number.parseInt(
          (Number.parseInt(wcompiler.current.style.width) -
            frame.getBoundingClientRect().x) /
            2
        )}px 0`;
        */
      }).catch(err=> showMessage(dispatch,err.response.status,"cannot init your session"))
      .finally(() => {
        dispatch({ type: "STOP_LOADING" });
      });
  }, []);


  const deployChanges = ()=> {

  }

  const openInNewTab = () => {
    visualiser(sessionId, fileName,rsType).then((resp) => {
      window.open(resp.data);
    }).catch(err=> showMessage(dispatch,err.response.status,"cannot vizualise you upload"));
  };



  const selectRsType = (e)=>{
    console.log(e)
    setRsType(e.target.value)
    
    let fInput = fRef.current.querySelector("input")
    switch(e.target.value){
      case "image":
        fInput.type="file"
        fInput.accept="image/*"
        fInput.click();
        break;
      case "carousel":  
        fInput.type="file"
        fInput.accept="image/*"
        fInput.setAttribute("multiple","")
        fInput.click();
        break;
      case "video":  
        fInput.type="file"
        fInput.accept="video/*"
        fInput.click();
        break;

      
    }
  }

  const validateUpload = () =>{
    dispatch({type: "START_LOADING"})
    uploadPubFiles(fRef.current.querySelector("input"), rsType)
    .then((resp) => {
      setFileName(resp.data)
    })
    .finally(()=>{
      dispatch({type: "STOP_LOADING"})
    })}




  return (
    <div className="publicite-workspace">
      <div className="file-upload">
        <span>Telecharger le contenu publicitaire</span>
        <Select value={rsType} onChange={selectRsType}>
          <MenuItem name="image" value="image">Image</MenuItem>
          <MenuItem name="carousel" value="carousel">Carousel</MenuItem>
          <MenuItem name="video" value="video">Video</MenuItem>
        </Select>
        <div>
          <Button ref={fRef}>
            <input style={{display: "none"}} />
          </Button>
          
          <Button
            color="primary"
            variant="outlined"
            onClick={validateUpload}
          >
            Valider
          </Button>
        </div>
      </div>
      <div className="upload-histroy">
        <span>Session id:</span>
        <span>{sessionId}</span>
        <span>uploaded file:</span>
        <span>
          <ul style={{display: (fileName === "" ? "none": "block")}}>
            {Array.from(fileName.split(",")).map((f, i)=> <li style={{maxWidth: "200px",
                                                                      textOverflow: "ellipsis",
                                                                      whiteSpace: "nowrap",
                                                                      overflow: "hidden",
                                                                      display: "block"}} key={i}>{f}</li>)}
          </ul>
        </span>
      </div>

      <div className="workspace-compiler">
        <div>
          <Button
            style={{ background: "orange" }}
            variant="contained"
            disabled={fileName === ""}
            onClick={openInNewTab}
          >
            Visualize
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={fileName === ""} onClick={()=>setOpen(true)}
          >
            Deploy
          </Button>
        </div>

    
      </div>
      <Modal open={open} className="deploy-pub-alert">
         <div className="pub-content">
            <div className="message">
              <i className="fa fa-warning fa-2x" />
              <span>Are u sure about deploying this version ?</span>
            </div>
    
            <div className="pub-toolbar">
              <Button color="primary" variant="outlined">Valider</Button>
              <Button color="secondary" variant="outlined" onClick={()=> setOpen(false)}>Annuler</Button>
            </div>

         </div>
      </Modal>
    </div>
  );
};

export default PubliciteComponent;
