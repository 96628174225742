import React from "react";
import { useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ErrorMessage = (props) => {
  const message = useSelector((state) => state.messageReducer);
  return (
    <Snackbar
      open={!message.hide}
      id="error-message-id"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        severity={message.type}
        open={!message.hide}
        style={{ display: (message.hide ? "none" : "flex")  }}
      >
        <div  style={{ display: "flex" , columnGap: "10px" , alignItems: "center" }}>
          <span>{message.code}:</span>
          <span style={{ fontSize: ".7em" }}>{message.content}</span>
        </div>
      </Alert>
    </Snackbar>
  );
};

export default ErrorMessage;
