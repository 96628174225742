import React, {useState, useEffect} from 'react'

const ActionResultPanel = props =>{

    const [actionResult,setActionResult] = useState(props.actionResult);
  
    useEffect(()=>{
      setActionResult(props.actionResult)
    },[props.actionResult]);
  
    return(<div
      className={
        actionResult === ""
          ? "hide  action-message"
          : `${actionResult} action-message`
      }
    >
      <i
        className={
          actionResult === "success"
            ? "fa fa-check-circle fa-2x"
            : "fa fa-ban fa-2x"
        }
      ></i>
  
      <span>
        {actionResult === "success"
          ? "Success Operation"
          : "Failed operation"}
      </span>
    </div>);
  }

  export default ActionResultPanel;