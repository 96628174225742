import React, { useState, useEffect } from "react";
import "./DashboardComponent.css";
import { Avatar, Box, Card, CardContent, LinearProgress, Grid, Typography, Modal } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MemoryIcon from '@material-ui/icons/Memory';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { Bar } from 'react-chartjs-2';
import { Button, CardHeader, Divider, useTheme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";


const DashboardComponent = (props) => {
  ChartJS.register(ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip);
  const theme = useTheme();

  const data1 = {
    datasets: [
      {
        backgroundColor: '#3F51B5',
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: [18, 5, 19, 27, 29, 19, 20],
        label: 'Up',
        maxBarThickness: 10
      },
      {
        backgroundColor: '#5D84B9',
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: [11, 20, 12, 29, 30, 25, 13],
        label: 'Down',
        maxBarThickness: 10
      }
    ],
    labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 Aug']
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  let [data, setData] = useState([])

  useEffect(() => {
    fetch('data.json')
      .then(res => res.json())
      .then(data => {
        setData(data)
      })
  }, [])
  let displayType = props.displayType

  let colors = ['hsl(15, 100%, 70%)', 'hsl(195, 74%, 62%)', 'hsl(348, 100%, 68%)', 'hsl(145, 58%, 55%)', 'hsl(264, 64%, 52%)', 'hsl(43, 84%, 65%)']

  return (
    <div>
      <div className="cards" >
        <Card className="card"
          sx={{ height: '100%' }}
          {...props}
        >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  TOTAL UP TIME
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  160 hours
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                style={{backgroundColor:"#083554"}}
                  sx={{
                    backgroundColor: 'error.main',
                    height: 56,
                    width: 56
                  }}
                >
                  <MoneyIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ArrowDownwardIcon color="error" />
              <Typography
                color="error"
                sx={{
                  mr: 1
                }}
                variant="body2"
              >
                100%
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                Since last month
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Card className="card"{...props}>
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  Number of Controllers
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  50
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                style={{backgroundColor:"#D43B1B"}}
                  sx={{
                    backgroundColor: 'success.main',
                    height: 56,
                    width: 56
                  }}
                >
                  <MemoryIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                pt: 2
              }}
            >
              <ArrowUpwardIcon color="success" />
              <Typography
                variant="body2"
                sx={{
                  mr: 1
                }}
              >
                100%
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                Since last month
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Card className="card"{...props}>
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  ACTIVE USERS
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  10
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                style={{backgroundColor:"green"}}
                  sx={{
                    backgroundColor: 'primary.main',
                    height: 56,
                    width: 56
                  }}
                >
                  <PeopleIcon />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
        className="card"
          sx={{ height: '100%' }}
          {...props}
        >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  POINT OF SALES PROGRESS
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  75.5%
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                style={{backgroundColor:"hsl(145, 58%, 55%)"}}
                  sx={{
                    backgroundColor: 'warning.main',
                    height: 56,
                    width: 56
                  }}
                >
                  <InsertChartIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box sx={{ pt: 3 }}>
              <LinearProgress
                value={75.5}
                variant="determinate"
              />
            </Box>
          </CardContent>
        </Card>
      </div>
      <Card {...props}>
        <CardHeader
          action={(
            <Button
              endIcon={<ArrowDropDownIcon fontSize="small" />}
              size="small"
            >
              Last 7 days
            </Button>
          )}
          title="Uptime"
        />
        <Divider />
        <CardContent>
          <Box
            sx={{
              height: 250,
              position: 'relative'
            }}
          >
            <Bar
              data={data1}
              options={options}
            />
          </Box>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon fontSize="small" />}
            size="small"
          >
            Overview
          </Button>
        </Box>
      </Card>
    </div>)
}
export default DashboardComponent;
