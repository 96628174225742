import { Breadcrumbs } from "@material-ui/core";
import React, { Component } from "react";
import "./navigator.css";
import "./toolbar.css";

import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";


const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

export default class Navigator extends Component {

  render() {
    return (
      <div className="Navigator">
        <Breadcrumbs>
          {this.props.nav.map((e, index) => {
            return <StyledBreadcrumb key={index} {...(e.onClick !== undefined ? {onClick: ()=> e.onClick()} : {}) } label={e.label} icon={e.tag === "img" ? <img src={e.icon} style={{width: ".8rem"}} alt="icon" /> : <i className={"fa " + e.icon }></i>} />;
          })}
        </Breadcrumbs>
      </div>
    );
  }
}
