import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ActionResultPanel from "../component/common/ActionResultPanel";
import "./Login.css";
import axios from "axios";
import Configuration from "../environment";
import { Redirect, withRouter } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { showMessage } from "../services/MessageService";

import {
  enableLoaderAction,
  disableLoaderAction,
} from "../actions/globat-actions";
import RedirectToAuth from "react-oidc/lib/RedirectToAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: 0,
    },
  },
}));

const Login = (props) => {
  const loginRef = React.createRef();

  const [username, setUsername] = useState("");
  const [usernameValidationLogic, setUsernameValidationLogic] = useState({});
  const [passwordValidationLogic, setPasswordValidationLogic] = useState({});

  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState();
  const [actionResult, setActionResult] = useState("");

  const dispatch = useDispatch();

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const performLogin = () => {
    setUsernameValidationLogic({});
    setPasswordValidationLogic({});
    let validationRuleFailed = false;
    if (username === "") {
      setUsernameValidationLogic({
        error: true,
        helperText: "Should not be blanked",
      });
      validationRuleFailed = true;
    }
    if (password === "") {
      setPasswordValidationLogic({
        error: true,
        helperText: "Should not be blanked",
      });
      validationRuleFailed = true;
    }

    if (validationRuleFailed) {
      return;
    }
    props.performLoading();
    axios
      .post(
        encodeURI(
          Configuration.backend_api_url +
            `/login?username=${username}&password=${password}`
        )
      )
      .then(
        (resp) => {
          setActionResult("success");
          //remove old items
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          localStorage.removeItem("userPic")

          // store JWTs token in localStorage
          localStorage.setItem("token", resp.headers.authorization);
          localStorage.setItem("user",                    JSON.parse(atob(
            localStorage
              .getItem("token")
              .replace("Bearer", "")
              .trim()
              .split(".")[1])).sub);
          props.endLoading();
          axios.get(Configuration.backend_api_url + "/users?page=0&pageSize=1&username=" + localStorage.getItem("user"), {
            headers: {
              authorization: localStorage.getItem("token")
            }
          })
          .then(resp=> localStorage.setItem("userPic", resp.data.content[0].pic))
          .finally(()=>{
            setTimeout(() => {
              setRedirect("/home");
            }, 2000);
          })
        },
        (err) => {
          showMessage(dispatch, err.message);
          setActionResult("failed");
          
        }
      ).finally(()=>{props.endLoading()});
  };
  const classe = useStyles();

  const [ssoToPerform,setSsoToPerform] = useState(false)

  const performSSO = ()=>{
    if(window.userManager === undefined){
      showMessage(dispatch,"cannot perform SSO , IDP is not reachable")
    }else{
      setSsoToPerform(true)
    }
  }


  if (redirect) {
    return <Redirect to="/home" />;
  }

  return (
    <div className="login" ref={loginRef}>
      <div>
        <div>
          <ActionResultPanel actionResult={actionResult} />
        </div>
        <div className="authentication-panel">
          <div>
            <img alt="" src="SRCS.png" height="90" width="90" />
          </div>

          <div className={`content ${classe.root}`}>
            <div>
              <span>
                <i style={{fontSize:'1em'}} className="fa fa-user" aria-hidden="true"></i>
              </span>

              <TextField
                required
                {...usernameValidationLogic}
                type="text"
                name="username"
                label="username"
                value={username}
                onChange={handleChangeUsername}
                variant="outlined"
              />
            </div>

            <div>
              <span>
                <i style={{fontSize:'1em'}} className="fa fa-lock" aria-hidden="true"></i>
              </span>
              <TextField
                required
                {...passwordValidationLogic}
                type="password"
                name="password"
                label="password"
                value={password}
                variant="outlined"
                onChange={handleChangePassword}
              />
            </div>

            <br />

            <Button data-testid="login" onClick={performLogin} color="primary" variant="contained">
              login
            </Button>
            <Button onClick={performSSO}>Connect With SSO </Button>
            {ssoToPerform ? <RedirectToAuth userManager={window.userManager}  /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  performLoading: () => dispatch(enableLoaderAction()),
  endLoading: () => dispatch(disableLoaderAction()),
});

export default withRouter(connect(null, mapDispatchToProps)(Login));
