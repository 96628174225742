import React from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import "./ActionModal.css"



const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const ActionModal = (props) => {


  let open = useSelector((state) => state.ModalReducer);
  const dispatch = useDispatch();


  const handleClose = () => {
    // clear the input file value ==> cause memory leak
    props.clearMethod()
    dispatch({ type: "CLOSE_MODAL" });
  };

  return (
    <div>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-export-content" style={{padding: "0"}}>
            {props.children}
        </DialogContent>
      </Dialog>

      {/* 
      <Dialog
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description" 
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} id="modal-content-id">
            {props.children}
          </div>
        </Fade>
      </Dialog>  */}
    </div>
  );
};

export default ActionModal;
