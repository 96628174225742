import React, { useEffect, useState } from "react";

import { FormControl, Input, InputLabel } from "@material-ui/core";
import axios from "axios";
import buildUrl from "build-url";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  disableLoaderAction, dispatchSelectCommand, enableLoaderAction
} from "../actions/globat-actions";
import DataTableToolbar from "../component/common/DatatableToolbar";
import FilterComponent from "../component/common/FilterComponent";
import SearchComponent from "../component/common/SearchComponent";
import EnhancerTatable from "../component/EnhancedTable";
import Configuration from "../environment";
import { deleteCommand } from "../services/CommandService";
import { showMessage } from "../services/MessageService";
import { toggleVisibility } from "../utils/effect.js";
import "./Command.css";
import "./Controller.css";

/**
 *
 *  the initialState const of the datatable
 */
const initalState = {
  totalElements: 0,
  page: 0,
  pageSize: 10,
  content: [],
};

/**
 *
 * The Headers cells definitions
 */
const commandHeadCells = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "creationDate",
    numeric: false,
    disablePadding: false,
    label: "Creation date",
  },
  {
    id: "commandAction",
    numeric: false,
    disablePadding: false,
    label: "Command action",
  },
  {id: "status", numeric: false, disablePadding: false, label: "Status"},

  {
    id: "executionDate",
    numeric: false,
    disablePadding: false,
    type: "icon",
    label: "Execution date",
  },
];

let blankCommand = {
  id: "",
  macAddresses: "",
  url: "",
  controllerName: "",
  pos: { id: 0 },
};

const mapDispatchToprops = (dispatch) => {
  return {
    refreshDatatable: () => dispatch({ type: "REFRESH_DATATABLE" }),
    enableLoading: () => dispatch(enableLoaderAction()),
    disableLoading: () => dispatch(disableLoaderAction()),
    selectCommand: (command) => dispatch(dispatchSelectCommand(command)),
  };
};

const fetchCommandByCriteria = async (criteria, page, pageSize) => {
  let noneBlankedCriteria = {};
  if (criteria.username !== "" && criteria.username !== undefined) {
    noneBlankedCriteria.username = criteria.username;
  }
  if (criteria.controllerName !== "" && criteria.controllerName !== undefined) {
    noneBlankedCriteria.controllerName = criteria.controllerName;
  }

  let uri = buildUrl(Configuration.backend_api_url, {
    path: "/commands",
    queryParams: {
      ...noneBlankedCriteria,
      page,
      pageSize,
    },
  });
  //+ encodeURIComponent(criteria);
  return await axios.get(uri, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
};

const EditCommand = (props) => {
  const command = useSelector((state) => state.selectCommandReducer);

  const backToDataPanel = () => {
    toggleVisibility("dataPanel", "editPanel");
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div id="editPanel" className="command edit-panel hide">
        <span className="edit-panel-header">
          <span className="icon-button">
            <i
              className="fa fa-arrow-left"
              tooltip="Back to Holders table"
              onClick={(e) => backToDataPanel()}
            ></i>
          </span>
          Command Information
        </span>

        <div className="edit-panel-content">
          <FormControl>
            <InputLabel>ID</InputLabel>
            <Input name="id" value={command.id} disabled />
          </FormControl>

          <FormControl>
            <InputLabel>Username</InputLabel>
            <Input name="username" disabled value={command.username} />
          </FormControl>
          <FormControl>
            <InputLabel>Controller name</InputLabel>
            <Input value={command.controllerName} disabled />
          </FormControl>

          <FormControl>
            <InputLabel>Command action</InputLabel>
            <Input value={command.commandAction} disabled />
          </FormControl>

          <FormControl>
            <InputLabel>Creation date</InputLabel>
            <Input disabled value={command.creationDate} />
          </FormControl>
          <FormControl>
            <InputLabel>Execution date</InputLabel>
            <Input
              name="executionDate"
              disabled
              value={command.executionDate}
            />
          </FormControl>
          <FormControl>
            <InputLabel>status</InputLabel>
            <Input name="status" disabled value={command.status} />
          </FormControl>
        </div>
        <div style={{display: "flex", justifyItems: "center", flexDirection: "column"}}>
          <h3 style={{textAlign: "center" }} >Taked Screen Shot</h3>
          <img src={command.data} width="100%" />
        </div>
      </div>
    </div>
  );
};

const CommandDataTable = connect(
  null,
  mapDispatchToprops
)((props) => {
  const [data, setData] = useState(initalState);

  const dispatch = useDispatch();

  const editActionCallback = (row) => {
    toggleVisibility("dataPanel", "editPanel");
    props.selectCommand(row);
  };

  const changePaginationActionHandler = (criteria, page, pageSize) => {
    props.enableLoading();

    fetchCommandByCriteria(criteria, page, pageSize).then(
      (response) => {
        setData(response.data);
        props.disableLoading();
      },
      (err) => {
        showMessage(dispatch, err.message);
        props.disableLoading();
      }
    );
  };

  useEffect(() => {
    changePaginationActionHandler(
      props.searchCriteria,
      initalState.page,
      initalState.pageSize
    );
  }, [props.refresh, props.searchCriteria, props.refresh]);

  const deleteCommandAction = (id, callback) => {
    dispatch({ type: "START_LOADING" });
    deleteCommand(id)
      .then((resp) => {
        callback();
        dispatch({ type: "STOP_LOADING" });
        dispatch({ type: "REFRESH_DATATABLE" });
      })
      .catch((err) => {
        dispatch({ type: "STOP_LOADING" });
        showMessage(dispatch, err.message);
      });
  };

  return (
    <div>
      <EnhancerTatable className="command-table"
        headCells={commandHeadCells}
        data={data}
        rowId="id"
        page={data.number}
        pageSize={data.pageable === undefined ? 0 : data.pageable.pageSize}
        searchCriteria={props.searchCriteria}
        editAction={editActionCallback}
        entityName="Command"
        deleteAction={deleteCommandAction}
        changePaginationAction={changePaginationActionHandler}
      />
    </div>
  );
});

const Command = (props) => {
  const dispatch = useDispatch();

  const [searchCriteria, setSearchCriteria] = useState({
    commandAction: "",
    username: "",
    controllerName: "",
    creationDate: "",
  });

  const refresh = useSelector(
    (state) => state.DatatableReducer.refreshDatatable
  );
  const selectedCommand = useSelector((state) => state.selectCommandReducer);

  dispatch({ type: "UPDATE_SEARCH_MODEL", payload: searchCriteria });

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <div id="dataPanel">
        <div
          className={
            Object.keys(searchCriteria).find(
              (key) => searchCriteria[key] !== ""
            ) !== undefined
              ? "filter-section"
              : "filter-section hide"
          }
        >
          <span>Active Filters:</span>
          <div>
            <FilterComponent
              searchCriteria={searchCriteria}
              setSearchCriteriaCallback={setSearchCriteria}
            />
          </div>
        </div>

        <DataTableToolbar
          dataPanel="dataPanel"
          editPanel="editPanel"
          entityName="Command"
          displayAdd={false}
          displayUpload={false}
          displayExport={true}
        >
          <SearchComponent
            searchModel={searchCriteria}
            searchAction={setSearchCriteria}
          />
        </DataTableToolbar>

        <CommandDataTable refresh={refresh} searchCriteria={searchCriteria} />
      </div>

      <EditCommand command={selectedCommand} />
    </div>
  );
};

export default Command;
