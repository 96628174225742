import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Button } from '@material-ui/core';


const DeleteDialogComponent = ({ openStatus, title, content, agreedCallbak , closeCallback }) => {

    
    const [open, setOpen] = useState(openStatus);

    
    const agreedAction = () => {
        setOpen(false);
        agreedCallbak();
    }

    const handleClose = () => {
        setOpen(false)
        closeCallback()
    }

    useEffect(()=>{
        setOpen(openStatus >0)
    },[openStatus,content])

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Disagree
          </Button>
                <Button onClick={agreedAction} color="primary" autoFocus>
                    Agree
          </Button>
            </DialogActions>
        </Dialog>
    </>
}




export default DeleteDialogComponent;