import React, { useEffect, useReducer, useRef } from "react";
import "./HorizentalMenu.css";
import { useDispatch } from "react-redux";
import DashboardIcon from '@material-ui/icons/Dashboard';
import * as PermissionService from '../services/PermissionService'

const NestedList = (props) => {
  function toggleMenu(e) {
    const divHeader = e.currentTarget === null ? e.target : e.currentTarget;

    if(divHeader.querySelector("ul") === null)
      return

    
    if (divHeader.querySelector("ul").classList.contains("show-menu")) {
      divHeader.querySelector("ul").classList.remove("show-menu");
      divHeader.classList.remove("arrow-bottom");
    } else {
      let e = document.querySelector(".show-menu");
      if (e !== null) {
        e.classList.remove("show-menu");
        e.parentElement.classList.remove("arrow-bottom");
      }
      divHeader.querySelector("ul").classList.add("show-menu");
      divHeader.classList.add("arrow-bottom");
    }
  }

 

  const dispatch = useDispatch();
  const dashboardRef = useRef();
  const adsRef = useRef();
  const managementRef = useRef();
  const [, forceUpdate] = useReducer(x => x + 1, 0);


  const selectPublicitetMenu = (e, props) =>{
    e.stopPropagation()
    if(e.currentTarget.querySelector("li").classList.contains("active")){
      return
    }
    document.querySelector(".active")?.classList?.remove("active");
    e.currentTarget.querySelector("li").classList.add("active");
    props.chooseMenu("Advertisement,Resources");
  }

  
  function selectManagementMenu(e, props) {
    e.stopPropagation()
    let selectedIndex;
    let i=0;
    document
    .querySelectorAll(".horizentalMenu  ul li").forEach(el=>{
      if(el.contains(e.target)){
        selectedIndex = i
        return
      }
      i++;
    })
    if(  document
      .querySelectorAll(".horizentalMenu  ul li").length === selectedIndex){
        return
      }

    let navigatorTitle;
    
    switch (selectedIndex) {
      case 0:
        navigatorTitle = "Controller";
        break;
        case 1:
        navigatorTitle = "PointOfSales";
        break;
        case 2:
        navigatorTitle = "Users";
        break;
        case 3:
        navigatorTitle = "Commands";
        break;
      default:
        navigatorTitle = ""  
    }
    let selectedLi = document
    .querySelectorAll(".horizentalMenu ul li")[selectedIndex];
    if(selectedLi === null || selectedLi === undefined){
      return
    }
    if (selectedLi.classList.contains("menu")) {
      document.querySelectorAll(".menu .level2").forEach((e) => {
          if(selectedLi.contains(e)){
            e.classList.toggle("hide");            
          }
        })

        
      let open = false;
      
      document.querySelectorAll(".menu .arrow-left").forEach((e) => {
        if(e.parentElement.contains(selectedLi)){
          e.classList.remove("arrow-left");
          e.classList.add("arrow-bottom");
          open = true;
        }
      });

      if (!open) {
        document.querySelectorAll(".menu .arrow-bottom").forEach((e) => {
          if(e.parentElement.contains(selectedLi)){
            e.classList.remove("arrow-bottom");
            e.classList.add("arrow-left");
          }
        });
      }
      return;
    } else {
      props.chooseMenu("Management," + navigatorTitle);
    }
    if (document.querySelector(".horizentalMenu .active") != null) {
      document.querySelector(".horizentalMenu .active").classList.remove("active");
    }
    selectedLi.classList.toggle("active");

  
    document.querySelectorAll(".menu .level2").forEach((e) => {
          if(!e.contains(selectedLi)){
            e.classList.add("hide")
          }
        });

    document.querySelectorAll(".menu .arrow-bottom").forEach((e) => {
      
        if(!e.parentElement.contains(selectedLi)){
        e.classList.remove("arrow-bottom");
        e.classList.add("arrow-left");
        }
      });
      
    if(window.screen.width < 460){
      document.querySelectorAll(".arrow-bottom")[0].click()
    }
  }


  useEffect(()=>{
    forceUpdate()
  },[])


  const openDashboard = (e)=>{
    let activeElement = document.querySelector(".active") 
    if(activeElement !== null){
      activeElement.classList.remove("active")
    }
    e.currentTarget.classList.add("active")
    props.chooseMenu("Dashboard");
  }
  return (
    <div className="horizentalMenu">

      <div>
      <div className="dashboard" data-sectag="dashobard-menu" ref={dashboardRef} onClick={openDashboard} style={{padding : "10px 0 0 10px" , display: PermissionService.displayMenu(dashboardRef)}}>
          <span >
            <DashboardIcon />
          </span>
          <span>Dashboard</span>
      </div>
        <div onClick={(e) => toggleMenu(e)} ref={managementRef} data-sectag="management-menu" className="arrow-left" style={{padding : "10px 0 0 10px", marginTop: "3px", display: PermissionService.displayMenu(managementRef)}} >
          <span>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </span>
          <span>Management</span>
        <ul className="level1" onClick={(e) => selectManagementMenu(e, props)}>
        
          <li data-transaction-name="controllerLi" name="controllerLi">
            <span>
              <i className="fa fa-microchip" aria-hidden="true"></i>
            </span>
            <span>Controller</span>
          </li>
          <li>
            <span>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            <span>Point of Sales</span>
          </li>
          <li style={{display:   localStorage.getItem("token") === null ? "none" : 
            JSON.parse(atob(localStorage.getItem("token").replace("Bearer", "").split(".")[1])).scopes.includes("ROLE_ADMIN") || 
            JSON.parse(atob(localStorage.getItem("token").replace("Bearer", "").split(".")[1])).scopes.includes("ROLE_SUPER_ADMIN") ? "block" : "none"}}>
            <span>
              <i className="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
            <span>Users</span>
          </li>
          <li>
            <span>
              <i className="fa fa-terminal" aria-hidden="true"></i>
            </span>
            <span>Commands</span>
          </li>
        </ul>
        </div>
        <div onClick={(e) => toggleMenu(e)} data-sectag="ads-menu" ref={adsRef} className="arrow-left" style={{padding : "10px 0 0 10px", marginTop: "3px" , display: PermissionService.displayMenu(adsRef)}} >
          <span>
            <i className="fa fa-solid fa-rectangle-ad"></i>
          </span>
          <span>Advertisement</span>
          <ul className="level1" onClick={(e) => selectPublicitetMenu(e, props)}>
            <li>
            <span>
              <i className="fa fa-file" aria-hidden="true"></i>
            </span>
            <span>Resources</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NestedList;
