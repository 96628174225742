import axios from "axios";
import Configuarion from "../environment";


const deleteCommand = async (commandId) => {
    
    await axios.delete(Configuarion.backend_api_url + "/commands", 
    {
        headers: {
            Authorization : localStorage.getItem("token")
        } , 
        params: {
          ids: commandId
        }
    });
    
}


export {deleteCommand};

